import React, { useContext, useEffect, useState } from "react";
import styles from "./Misedificios.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import {
  faArrowLeft,
  faClock,
  faArrowRight,
  faEye,
  faAd,
  faAdd,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoadContext } from "../../load/LoadProvider";

const Misedificios = () => {
  const [data, setData] = useState([]);
  const [addModalDisplay, setAddModalDisplay] = useState(false);
  const { setLoading, isLoading } = useContext(LoadContext);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [columnVisibility, setColumnVisibility] = useState({
    Edif_ID: false,
    Nombre: true,
    Unidad: true,
    Direccion: true,
    Administracion: true,
    Acciones: true,
  });
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filtering, setFiltering] = useState(searchParams.get("search"));
  useEffect(() => {
    updateData();
  }, []);
  const columnDef = [
    {
      accessorKey: "Edif_ID",
      header: "Edif_ID",
    },
    {
      accessorKey: "Nombre",
      header: "Nombre",
    },
    {
      accessorKey: "Direccion",
      header: "Dirección",
    },
    {
      accessorKey: "Unidad",
      header: "Unidad",
    },
    {
      accessorKey: "Tipo",
      header: "Tipo",
    },
    {
      accessorKey: "Administracion",
      header: "Administración",
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Reservar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-success"}
              onClick={() =>
                navigate({
                  pathname: "reservar/" + row.original.Edif_ID,
                  search: searchParams.toString(),
                })
              }
            >
              {" "}
              <FontAwesomeIcon icon={faClock} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Ver Mis Reservas</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-warning ms-3"}
              onClick={() =>
                navigate({
                  pathname:
                    "misreservas/" +
                    row.original.Edif_ID +
                    "/" +
                    row.original.Nombre,
                  search: searchParams.toString(),
                })
              }
            >
              {" "}
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: columnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      //...
      columnVisibility,
      pagination,
      globalFilter: filtering,
    },
    onGlobalFilterChange: setFiltering,
  });

  function addEdif(e) {
    e.preventDefault();
    setAddModalDisplay(false);
    setLoading(true);
    let data = new FormData(e.target);
    data.append("mode", 1);
    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          showModal(
            "Correcto",
            "Se ha agregado el edificio " +
              res.data.data[0].Nombre +
              " correctamente"
          );
          updateData();
        } else {
          toast.error(
            "Ha ocurrido un error agregando el edificio, revise el código"
          );
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error agregando el edificio");
          console.log(error);
        }
      });
  }
  function updateData() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/edificios.php?mode=0")
      .then((res) => {
        if (res.data.status == "OK") {
          setData(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={styles.mainContainer}>
      {addModalDisplay ? (
        <form className={styles.addModalContainer} onSubmit={addEdif}>
          {" "}
          <div className={"rounded-5 " + styles.addModal}>
            <div
              className={"w-100 rounded-top-5 d-flex flex-row " + styles.topBar}
            >
              <h2>Agregar Edificio</h2>
            </div>

            <div
              className={
                styles.mainText +
                " d-flex flex-column align-item-center justify-content-center w-50"
              }
            >
              <label className={"mb-3"}>
                Ingrese el código proporcionado por su administrador:
              </label>
              <input
                type="text"
                className={"form-control"}
                name="code"
                placeholder="Código"
              />
            </div>
            <div className={styles.buttonsContainer}>
              <button type="submit" className="btn btn-success">
                {" "}
                Agregar
              </button>
              <button
                type="button"
                onClick={() => setAddModalDisplay(false)}
                className="btn btn-outline-danger"
              >
                {" "}
                Cancelar
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

      <span
        className={
          "d-flex align-items-center justify-content-center flex-row w-100 mt-4"
        }
      >
        <button
          className={"btn btn-success me-4"}
          onClick={() => setAddModalDisplay(true)}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
        <h3>Mis Edificios</h3>
      </span>

      <hr className={styles.hr} />
      <span
        className="w-100 d-flex flex-row align-items-center justify-content-center pb-3"
        style={{ maxWidth: "10cm" }}
      >
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          placeholder="Nombre/Dirección"
          className="form-control ms-3"
          value={filtering}
          onChange={(e) => {
            setFiltering(e.target.value);
            setSearchParams({ search: e.target.value });
          }}
        />
      </span>
      <div className={"w-100 " + styles.tableContainer}>
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span
        className={"d-flex flex-row align-items-center justify-content-center"}
      >
        <button
          onClick={tableInstance.previousPage}
          disabled={!tableInstance.getCanPreviousPage()}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>{" "}
        <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
        <button
          disabled={!tableInstance.getCanNextPage()}
          onClick={tableInstance.nextPage}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </span>
    </div>
  );
};

export default Misedificios;
