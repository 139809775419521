import React, { useContext, useEffect, useState } from "react";
import styles from "./Administraciones.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  faArrowLeft,
  faClock,
  faArrowRight,
  faGear,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoadContext } from "../../load/LoadProvider";

const Administraciones = () => {
  const [data, setData] = useState([]);
  const { setLoading, isLoading } = useContext(LoadContext);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [columnVisibility, setColumnVisibility] = useState({
    Adm_ID: false,
    Nombre: true, //hide this column by default
    Email: true,
    Direccion: true,
    Telefono: true,
  });
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    updateData();
  }, []);
  const columnDef = [
    {
      accessorKey: "Adm_ID",
      header: "Adm_ID",
    },
    {
      accessorKey: "Nombre",
      header: "Nombre",
    },
    {
      accessorKey: "Email",
      header: "Email",
    },
    {
      accessorKey: "Direccion",
      header: "Dirección",
    },
    {
      accessorKey: "Telefono",
      header: "Teléfono",
    },
    {
      header: "Acciones",
      cell: ({ row, original }) => (
        <>
          {!row.getCanExpand() && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <strong>Gestionar</strong>
                </Tooltip>
              }
            >
              <Button
                className={"btn btn-warning"}
                onClick={() =>
                  navigate("administrar/" + row.original.Adm_ID + "/info")
                }
              >
                {" "}
                <FontAwesomeIcon icon={faGear} />
              </Button>
            </OverlayTrigger>
          )}
        </>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: columnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      //...
      columnVisibility,
      pagination,
    },
  });

  function updateData() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/administraciones.php?mode=0")
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={styles.mainContainer}>
      <h3 className={"mt-4"}>Administraciones</h3>
      <hr className={styles.hr} />
      <div className={"w-100 " + styles.tableContainer}>
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span
        className={"d-flex flex-row align-items-center justify-content-center"}
      >
        <button
          onClick={tableInstance.previousPagePage}
          disabled={!tableInstance.getCanPreviousPage()}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>{" "}
        <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
        <button
          disabled={!tableInstance.getCanNextPage()}
          onClick={tableInstance.nextPage}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </span>
    </div>
  );
};

export default Administraciones;
