import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./AdministracionInfo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../load/LoadProvider";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";

const AdministracionInfo = () => {
  const { admid } = useParams();
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const [admInfo, setAdmInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    updateAdm();
  }, []);

  function updateAdm() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL +
          "/administraciones.php?mode=2&adm=" +
          admid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          setAdmInfo(res.data.data);
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.request && error.request.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(error);
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    showModal(
      "Confirmación",
      "¿Está seguro/a que desea modificar los datos del edificio?",
      () => {
        setLoading(true);
        const data = new FormData(e.target);
        data.append("mode", 1);
        data.append("adm", admid);

        axios
          .post(process.env.REACT_APP_APIURL + "/administraciones.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status === "OK") {
              toast.success("Administración modificada correctamente");
              updateAdm();
            } else if (res.data.status === "NOMODIF") {
              toast.error("No ha modificado la administración");
            } else {
              toast.error("Ha ocurrido un error en la conexión al servidor");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.request && error.request.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error en la conexión al servidor");
              console.log(error);
            }
          });
      }
    );
  }
  return (
    <div className=" w-100 d-flex flex-column align-items-center pt-4">
      {admInfo ? (
        <form
          className={
            "d-flex flex-column w-100 align-items-center " + styles.form
          }
          onSubmit={handleSubmit}
        >
          <label className={"pb-2"}>
            <strong>Nombre</strong>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre"
            name="nombre"
            defaultValue={admInfo.Nombre}
            maxLength={50}
          />
          <label className={"pb-2 mt-4"}>
            <strong>Dirección</strong>
          </label>
          <input
            type="text"
            name="direccion"
            className="form-control"
            maxLength={100}
            defaultValue={admInfo.Direccion}
            placeholder="Dirección"
          />
          <label className={"pb-2 mt-4"}>
            <strong>Email</strong>
          </label>
          <input
            type="text"
            name="email"
            className="form-control"
            maxLength={50}
            defaultValue={admInfo.Email}
            placeholder="Email"
          />
          <label className={"pb-2 mt-4"}>
            <strong>Teléfono</strong>
          </label>
          <input
            type="text"
            name="telefono"
            className="form-control"
            maxLength={9}
            defaultValue={admInfo.Telefono}
            placeholder="Teléfono"
          />

          <button type="submit" className="btn btn-success mt-5 w-100">
            Confirmar
          </button>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdministracionInfo;
