import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./Reservar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faBuilding,
  faCalendar,
  faClapperboard,
  faClock,
  faClockFour,
  faRestroom,
  faSpaceShuttle,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { LoadContext } from "../../load/LoadProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const Reservar = () => {
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { edifid } = useParams();
  const { isLoading, setLoading } = useContext(LoadContext);

  //listas de cosas
  const [espacios, setEspacios] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [extras, setExtras] = useState([]);
  const [unidades, setUnidades] = useState(null);

  //seleccionados
  const [espacio, setEspacio] = useState(0);
  const [dia, setDia] = useState("");
  const [turno, setTurno] = useState(0);
  const [unidad, setUnidad] = useState(0);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    getEspacios();
  }, []);

  useEffect(() => {
    getTurnos();
  }, [espacio, dia]);

  useEffect(() => {
    if (espacios.find((esp) => esp["Esp_ID"] == espacio)) {
      setExtras(espacios.find((esp) => esp["Esp_ID"] == espacio)["extras"]);
    }
  }, [espacio]);

  useEffect(() => {
    //total
    if (dia && espacio && turno) {
      let tot = turnos.find((t) => t["Hor_ID"] == turno)["Costo"];
      extras.forEach((ext) => {
        if (ext.selected) {
          tot = parseInt(tot) + parseInt(ext["Costo"]);
        }
      });
      setTotal(tot);
    } else {
      setTotal(0);
    }
  }, [espacio, dia, turno, extras]);

  function getTurnos() {
    if (dia && espacio) {
      //get turnos
      axios
        .get(
          process.env.REACT_APP_APIURL +
            `/edificios.php?mode=3&dia=${dia.slice(0, 10)}&esp=${espacio}`
        )
        .then((res) => {
          if (res.data.status == "OK") {
            setTurnos(res.data.data);
            setTurno(0);
            if (res.data.unidades) {
              setUnidades(res.data.unidades);
              setUnidad(0);
            }
          } else {
            toast.error("Ha ocurrido un error en la conexión al servidor");
            console.log(res);
          }
        })
        .catch((error) => {
          if (error.request && error.request.status == 401) {
            signOut();
            showModal(
              "Error de autenticación",
              "Su sesión ha expirado, por favor vuelva a iniciar sesión",
              () => {
                navigate("/");
              },
              () => {
                navigate("/");
              }
            );
          } else {
            toast.error("Ha ocurrido un error en la conexión al servidor");
            console.log(error);
          }
        });
    }
  }

  function handleExtraChange(e) {
    const updatedExtras = [...extras];
    updatedExtras.find((ext) => ext["Ext_ID"] == e.target.value).selected =
      e.target.checked;
    setExtras(updatedExtras);
  }
  function getEspacios() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=2&edif=" + edifid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          if (res.data.data != "") {
            setEspacios(res.data.data);
            setEspacio(0);
          } else {
            navigate("/dashboard/misedificios");
            showModal(
              "Aviso",
              "Este edificio no tiene espacios disponibles para reservar actualmente."
            );
          }
        } else if (res.data.status == "NOPUEDE") {
          navigate("/dashboard/misedificios");
          showModal(
            "Aviso",
            <div className="d-flex flex-column align-items-center pt-1 pb-1">
              Usted no puede reservar en este edificio ya que tiene pendientes
              los siguientes pagos:
              <div
                className="p-2 mt-4 mb-4"
                style={{
                  backgroundColor: "rgb(240,240,240)",
                  maxHeight: "3cm",
                  overflowY: "auto",
                  fontSize: "1rem",
                }}
              >
                <table
                  className="rounded-2 p-4"
                  style={{
                    backgroundColor: "rgb(240,240,240)",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Detalle</th>
                      <th className="ps-3">Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {res.data.deudas.map((deuda) => {
                      return (
                        <tr style={{ borderTop: "solid 1px black" }}>
                          <td
                            style={{
                              maxWidth: "5cm",
                              overflowX: "auto",
                              wordWrap: "break-word",
                            }}
                          >
                            {deuda.Detalle}
                          </td>
                          <td className="ps-3">
                            {deuda.Moneda + deuda.Importe}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.request && error.request.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(error);
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (espacio && turno && dia && (!unidades || unidad)) {
      let data = new FormData(e.target);
      data.append("mode", 0);
      showModal(
        "Confirmar",
        "Está seguro/a que desea enviar esta reserva?",
        () => {
          setLoading(true);
          axios
            .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
            .then((res) => {
              setLoading(false);
              if (res.data.status == "OK") {
                showModal(
                  "Confirmación",
                  'Su reserva ha sido enviada, pronto será confirmada. Puede ver su estado accediendo a "mis reservas"',
                  () => {
                    navigate("/dashboard/misedificios");
                  },
                  () => {
                    navigate("/dashboard/misedificios");
                  }
                );
              } else {
                toast.error("Ha ocurrido un error creando la reserva");
                console.log(res);
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.request && error.request.status == 401) {
                signOut();
                showModal(
                  "Error de autenticación",
                  "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                  () => {
                    navigate("/");
                  },
                  () => {
                    navigate("/");
                  }
                );
              } else {
                toast.error("Ha ocurrido un error en la conexión al servidor");
                console.log(error);
              }
            });
        }
      );
    } else {
      toast.error("No ha ingresado los datos");
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <button
          onClick={() => {
            navigate("/dashboard/misedificios");
          }}
          className={"btn btn-outline-primary text-center w-25 "}
        >
          <FontAwesomeIcon className={"me-3"} icon={faArrowLeft} />
          Volver
        </button>
        <h3 className={"align-self-center"}>
          <FontAwesomeIcon className={"me-2"} icon={faClock} />
          Reservar Espacio
        </h3>
      </div>
      <form
        className={"pt-3 d-flex flex-md-row flex-column"}
        onSubmit={handleSubmit}
      >
        <div className={"w-100 me-5"}>
          <label className={"pb-2"}>
            <FontAwesomeIcon className={"me-2"} icon={faCalendar} />
            <strong>Día</strong>
          </label>
          <input
            type="date"
            className={"form-control mb-4"}
            onChange={(e) => {
              setDia(e.target.value);
            }}
            value={dia}
            name={"fecha"}
            min={new Date(new Date().toDateString()).toISOString().slice(0, 10)}
            style={{ border: dia ? "" : "1px solid red" }}
            required
          />
          <label className={"pb-2"}>
            <FontAwesomeIcon className={"me-2"} icon={faWarehouse} />
            <strong>Espacio</strong>
          </label>
          <Form.Select
            name={"espacio"}
            required
            className={"mb-4"}
            onChange={(e) => {
              setEspacio(e.target.value);
            }}
            value={espacio}
            style={{ border: espacio ? "" : "1px solid red" }}
          >
            <option disabled style={{ fontStyle: "italic" }} value={0}>
              <i>Seleccionar espacio...</i>
            </option>
            {espacios.map((esp, index) => (
              <option key={index} value={esp["Esp_ID"]}>
                {esp["Nombre"]}
              </option>
            ))}
          </Form.Select>

          {unidades ? (
            <>
              <label className={"pb-2"}>
                <FontAwesomeIcon className={"me-2"} icon={faBuilding} />
                <strong>Unidad</strong>
              </label>
              <Form.Select
                name={"unidad"}
                required
                className={"mb-4"}
                onChange={(e) => {
                  setUnidad(e.target.value);
                }}
                value={unidad}
                style={{ border: unidad ? "" : "1px solid red" }}
              >
                <option disabled style={{ fontStyle: "italic" }} value={0}>
                  <i>Seleccionar unidad...</i>
                </option>
                {unidades.map((uni, index) => (
                  <option key={index} value={uni["Codigo"]}>
                    {uni["Unidad"]}
                  </option>
                ))}
              </Form.Select>
            </>
          ) : (
            ""
          )}

          <label className={"pb-2"}>
            <FontAwesomeIcon className={"me-2"} icon={faClockFour} />
            <strong>Turno</strong>
          </label>
          <Form.Select
            disabled={!dia}
            onChange={(e) => {
              setTurno(e.target.value);
            }}
            className={"mb-4"}
            value={turno}
            name={"turno"}
            style={{ border: turno ? "" : "1px solid red" }}
            required
          >
            <option disabled style={{ fontStyle: "italic" }} value={0}>
              <i>Seleccionar turno...</i>
            </option>
            {turnos.map((turn, index) => (
              <option
                key={index}
                style={{
                  backgroundColor:
                    turn["Disponible"] == 1
                      ? "rgb(100, 200, 100)"
                      : "rgb(255, 100, 100)",
                  color: "white",
                }}
                disabled={turn["Disponible"] != 1}
                value={turn["Hor_ID"]}
              >
                {turn["Disponible"] == 1
                  ? `${turn["Nombre"]} - ${turn["Moneda"]}${turn["Costo"]}`
                  : turn["Nombre"] + " - No Disponible"}
              </option>
            ))}
          </Form.Select>
        </div>

        <div className={"w-100 d-flex flex-column"}>
          {" "}
          <label className={"pb-2"}>
            <FontAwesomeIcon className={"me-2"} icon={faAdd} />
            <strong>Extras</strong>
          </label>
          {espacio
            ? extras.map((ext, index) => (
                <span key={index}>
                  <input
                    type="checkbox"
                    className="me-3"
                    value={ext["Ext_ID"]}
                    onChange={handleExtraChange}
                    name={"ext" + ext["Ext_ID"]}
                  />
                  <strong>{ext["Nombre"]}</strong>
                  {" - " +
                    espacios.find((esp) => esp["Esp_ID"] == espacio)["Moneda"] +
                    ext["Costo"]}
                </span>
              ))
            : "-"}
          <hr></hr>
          <h4>
            Total:{" "}
            {espacio
              ? espacios.find((esp) => esp["Esp_ID"] == espacio)["Moneda"]
              : ""}
            {total}
          </h4>
          <button
            type="submit"
            disabled={!(dia && espacio && turno)}
            className={"btn btn-success w-100 mt-3"}
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Reservar;

function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
