import React, { useState } from "react";
import { ModalContext } from "./ModalContext";
import Modal from "./Modal";

const ModalProvider = ({ children }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [confirmFunction, setConfirmFunction] = useState(null);
  const [cancelFunction, setCancelFunction] = useState(null);
  function closeModal() {
    setIsVisible(false);
  }
  function showModal(
    title,
    text,
    confirmFunction = () => {
      closeModal();
    },
    cancelFunction = () => {
      closeModal();
    }
  ) {
    setTitle(title);
    setText(text);
    setConfirmFunction(() => confirmFunction);
    setCancelFunction(() => cancelFunction);
    setIsVisible(true);
  }

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      <Modal
        title={title}
        text={text}
        show={isVisible}
        cancelFunction={cancelFunction}
        confirmFunction={confirmFunction}
        closeModal={closeModal}
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
