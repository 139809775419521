import React, { useContext, useState } from "react";
import styles from "./CrearTurno.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import {
  faAdd,
  faCalendar,
  faClose,
  faDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faPencil,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../../../load/LoadProvider";
import { ModalContext } from "../../../../../modal/ModalContext";
import { AuthContext } from "../../../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";

const CrearTurno = () => {
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const { espid } = useParams();

  const navigate = useNavigate();
  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    const data = new FormData(e.target);
    if (
      !data.get("lunes") &&
      !data.get("martes") &&
      !data.get("miercoles") &&
      !data.get("jueves") &&
      !data.get("viernes") &&
      !data.get("sabado") &&
      !data.get("domingo")
    ) {
      toast.error("Debe seleccionar al menos un día");
      setLoading(false);
      return;
    }
    data.append("mode", 10);
    data.append("espacio", espid);

    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Turno agregado correctamente");
          navigate(-1);
        } else {
          toast.error("Ha ocurrido un error agregando el espacio");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={"d-flex flex-column align-items-center pt-1"}>
      <hr className={"w-100"} />
      <h3>Crear Turno</h3>
      <hr className={"w-25"} />
      <form
        onSubmit={handleSubmit}
        className={"d-flex flex-column align-items-center w-100"}
        style={{ maxWidth: "20cm" }}
      >
        <div className={"col-12 align-items-center p-3 row"}>
          <div className="col-12">
            <label className={"pb-2"}>
              <FontAwesomeIcon icon={faPencil} className={"me-2"} />
              <strong>Nombre:</strong>
            </label>
            <input
              type="text"
              className={"form-control mb-4"}
              name={"nombre"}
              maxLength={"50"}
              minLength={"3"}
              required
            />
            <label className={"pb-2"}>
              <FontAwesomeIcon icon={faDollar} className={"me-2"} />
              <strong>Precio:</strong>
            </label>
            <input
              type="number"
              className={"form-control mb-4"}
              name={"precio"}
              maxLength={"6"}
              required
            />
            <label className={"pb-2"}>
              <FontAwesomeIcon icon={faCalendar} className={"me-2"} />
              <strong>Días:</strong>
            </label>
            <div className="w-100 row">
              <div className="col-12 col-md-6">
                {" "}
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Lunes:</strong>
                  <input type="checkbox" name="lunes" />
                </span>
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Martes:</strong>
                  <input type="checkbox" name="martes" />
                </span>
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Miércoles:</strong>
                  <input type="checkbox" name="miercoles" />
                </span>
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Jueves:</strong>
                  <input type="checkbox" name="jueves" />
                </span>
              </div>{" "}
              <div className="col-12 col-md-6">
                {" "}
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Viernes:</strong>
                  <input type="checkbox" name="viernes" />
                </span>
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Sábado:</strong>
                  <input type="checkbox" name="sabado" />
                </span>
                <span className="w-100 d-flex align-items-center">
                  <strong className="me-2">Domingo:</strong>
                  <input type="checkbox" name="domingo" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success w-100 mt-5 p-3 m-2">
          <FontAwesomeIcon icon={faAdd} className="me-2" />
          Crear
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="btn btn-outline-danger w-100 mt-2"
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default CrearTurno;
