import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {

        //check auth with be
        axios.get(process.env.REACT_APP_APIURL + "/auth.php?mode=0").then(res => {
            if (res.data.status === "OK") {
                signIn(res.data.user.id, res.data.user.name, res.data.user.email);
                setLoading(false);
            } else {
                signOut();
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            signOut();
            toast.error("Ha ocurrido un error obteniendo datos del servidor");
            console.log(error);
        })
    });
    function signIn(id, nombre, email) {
        localStorage.setItem("auth_", true);
        localStorage.setItem("authName", nombre);
        localStorage.setItem("authEmail", email);
        localStorage.setItem("authId", id);
        setLoggedIn(true);
    }
    function signOut() {
        axios.get(process.env.REACT_APP_APIURL + "/auth.php?mode=-1").catch(error => {
            toast.error("Ha ocurrido un error obteniendo conectando al servidor");
            console.log(error);
        });
        localStorage.removeItem("auth_");
        localStorage.removeItem("authName");
        localStorage.removeItem("authEmail");
        localStorage.removeItem("authId");
        setLoggedIn(false);
    }
    return <AuthContext.Provider value={{ signIn, isLoggedIn, signOut }}>{isLoading ? "" : children}</AuthContext.Provider>;
};

export default AuthProvider;
