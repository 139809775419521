import {
  filterFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadContext } from "../../../../load/LoadProvider";
import styles from "./Deudas.module.css";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faDollar,
  faDollarSign,
  faList,
  faMoneyBill,
  faPencil,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";

const Deudas = () => {
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadContext);
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modifyModalDisplay, setModifyModalDisplay] = useState({
    display: false,
    code: null,
    mode: null,
  });
  const unidadField = useRef();
  const tipoField = useRef();
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [columnVisibility, setColumnVisibility] = useState({
    Deuda_ID: false,
  });
  const [filtering, setFiltering] = useState("");
  const { edifid } = useParams();
  useEffect(() => {
    updateData();
  }, []);

  const conlumnDef = [
    {
      header: "Deuda_ID",
      accessorKey: "Deuda_ID",
    },
    {
      header: "Usuario",
      accessorKey: "Usuario",
    },
    {
      header: "Unidad",
      accessorKey: "Unidad",
    },
    {
      header: "Detalle",
      accessorKey: "Detalle",
    },
    {
      header: "Importe",
      accessorKey: "Importe",
    },
    {
      header: "Moneda",
      accessorKey: "Moneda",
    },
    {
      header: "Estado",
      accessorKey: "Estado",
      cell: ({ row }) => {
        if (row.original.Estado == "Pendiente") {
          return <strong style={{ color: "red" }}>Pendiente</strong>;
        } else if (row.original.Estado == "Pago") {
          return <strong style={{ color: "green" }}>Pago</strong>;
        }
      },
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          {row.original.Estado == "Pendiente" ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <strong>Pagar</strong>
                </Tooltip>
              }
            >
              <Button
                className={"btn btn-success ms-3"}
                onClick={() => {
                  handlePay(row.original.Deuda_ID);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faDollarSign} />
              </Button>
            </OverlayTrigger>
          ) : (
            ""
          )}

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Eliminar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-danger ms-3"}
              onClick={() => {
                handleDelete(row.original.Deuda_ID);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: conlumnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    globalFilterFn: "includesString",
    state: {
      pagination,
      globalFilter: filtering,
      columnVisibility,
    },
  });
  function updateData() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=12&edif=" + edifid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setData(res.data.data);
          setUnidades(res.data.unidades);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleDelete(id) {
    showModal(
      "Eliminar",
      "¿Está seguro/a que desea eliminar este saldo pendiente? (No se puede deshacer)",
      () => {
        setLoading(true);
        let data = new FormData();
        data.append("id", id);
        data.append("mode", 13);

        axios
          .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Saldo eliminado correctamente");
              updateData();
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }

  function handlePay(id) {
    showModal(
      "Pagar",
      "¿Está seguro/a que desea marcar como pago este saldo pendiente?",
      () => {
        setLoading(true);
        let data = new FormData();
        data.append("id", id);
        data.append("mode", 14);

        axios
          .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Saldo pago correctamente");
              updateData();
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }
  function handleAdd(e) {
    e.preventDefault();
    setLoading(true);
    let data = new FormData(e.target);
    data.append("edif", edifid);
    data.append("mode", 12);
    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        setModifyModalDisplay((prev) => {
          return { display: false, code: null, mode: null };
        });
        if (res.data.status == "OK") {
          toast.success("Saldo pendiente agregado correctamente");
          updateData();
        } else {
          toast.error("Ha ocurrido un error modificando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center pt-4 ">
      {modifyModalDisplay.display ? (
        <form
          className={styles.addModalContainer}
          onSubmit={(e) => {
            handleAdd(e);
          }}
        >
          {" "}
          <div className={"rounded-5 " + styles.addModal}>
            <div
              className={"w-100 rounded-top-5 d-flex flex-row " + styles.topBar}
            >
              <h2>Agregar saldo pendiente</h2>
            </div>

            <div
              className={
                styles.mainText +
                " d-flex flex-column align-items-center justify-content-start w-50"
              }
              style={{ overflowY: "auto" }}
            >
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="col-12 col-md-6 me-2">
                  <label>
                    <FontAwesomeIcon icon={faBuilding} className="me-2" />
                    <strong>Unidad</strong>
                  </label>
                  <select name="unidad" className="form-control mb-3" required>
                    {unidades.map((unidad) => (
                      <option value={unidad["Codigo"]}>
                        {unidad["Unidad"]}
                      </option>
                    ))}
                  </select>
                  <label>
                    <FontAwesomeIcon icon={faList} className="me-2" />
                    <strong>Detalle</strong>
                  </label>
                  <textarea
                    type="text"
                    name="detalle"
                    defaultValue={""}
                    required
                    className="form-control mb-3"
                  ></textarea>
                </div>
                <div className="col-12 col-md-6">
                  <label>
                    <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                    <strong>Moneda</strong>
                  </label>
                  <select name="moneda" className="form-control mb-3" required>
                    <option value="UYU">Pesos</option>
                    <option value="USD">Dolares</option>
                    <option value="UI">Unidades Indexadas</option>
                    <option value="UR">Unidades Reajustables</option>
                  </select>
                  <label>
                    <FontAwesomeIcon icon={faDollarSign} className="me-2" />
                    <strong>Importe</strong>
                  </label>
                  <input
                    type="number"
                    name="importe"
                    defaultValue={0}
                    className="form-control mb-3"
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <button type="submit" className="btn btn-success">
                {" "}
                Agregar
              </button>
              <button
                type="button"
                onClick={() => {
                  setModifyModalDisplay((prev) => {
                    return { display: false, code: null, mode: null };
                  });
                }}
                className="btn btn-outline-danger"
              >
                {" "}
                Cancelar
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
      <span
        className="w-100 mb-4 d-flex align-items-center flex-row justify-content-center"
        style={{ maxWidth: "10cm" }}
      >
        <button
          className={"btn btn-success"}
          onClick={() => {
            setModifyModalDisplay((prev) => {
              return { display: true, mode: 0, code: null };
            });
          }}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
        <div className="vr me-3 ms-3"></div>
        <FontAwesomeIcon icon={faSearch} className="me-4" />
        <input
          type="text"
          className="form-control"
          placeholder="Buscar"
          onChange={(e) => setFiltering(e.target.value)}
          value={filtering}
        />
      </span>
      <div
        className={"w-100 " + styles.tableContainer}
        style={{ overflowX: "auto" }}
      >
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: "10cm",
                      overflowX: "auto",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <span
          className={
            "d-flex flex-row align-items-center w-100 justify-content-center"
          }
        >
          <button
            onClick={tableInstance.previousPage}
            disabled={!tableInstance.getCanPreviousPage()}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>{" "}
          <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={tableInstance.nextPage}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default Deudas;
