import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModalContext } from "../../modal/ModalContext";
import { LoadContext } from "../../../load/LoadProvider";

const Passreset = () => {
  const [sent, setSent] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState(0);
  const { showModal } = useContext(ModalContext);
  const { setLoading } = useContext(LoadContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("reset")) {
      setMode(1);
    }
  }, []);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    if (mode === 1) {
      let data = new FormData(e.target);
      if (data.get("pass") !== data.get("repass")) {
        setSent(1);
      }
      data.append("mode", 5);
      data.append("email", searchParams.get("email"));
      data.append("code", searchParams.get("code"));
      axios
        .post(process.env.REACT_APP_APIURL + "/auth.php", data)
        .then((res) => {
          setLoading(false);
          if (res.data.status === "OK") {
            showModal(
              "Confirmado",
              "Su contraseña ha sido cambiada, ya puede iniciar sesión.",
              () => {
                navigate("/");
              },
              () => {
                navigate("/");
              }
            );
          } else {
            setSent(-1);
          }
        })
        .catch((err) => {
          setLoading(false);
          setSent(-1);
          console.log(err);
        });
    } else if (mode === 0) {
      let data = new FormData(e.target);
      data.append("mode", 4);
      axios
        .post(process.env.REACT_APP_APIURL + "/auth.php", data)
        .then((res) => {
          if (res.data.status === "OK") {
            setLoading(false);
            showModal(
              "Enviado",
              "Se le ha enviado un email, por favor revise su casilla y siga las instrucciones.",
              () => {
                navigate("/");
              },
              () => {
                navigate("/");
              }
            );
          } else {
            setSent(-1);
          }
        })
        .catch((err) => {
          setLoading(false);
          setSent(-1);
          console.log(err);
        });
    }
  }
  return (
    <div className="p-4">
      {mode === 1 ? (
        <>
          <h3 className="mt-5 mb-5">Por favor, ingrese su nueva contraseña.</h3>
          <form onSubmit={handleSubmit}>
            <strong>Contraseña: </strong>
            <input
              type="password"
              name="pass"
              minLength={3}
              maxLength={15}
              className="form-control"
              placeholder="Contraseña"
            ></input>
            <strong>Repetir Contraseña: </strong>
            <input
              type="password"
              name="repass"
              minLength={3}
              maxLength={15}
              className="form-control"
              placeholder="Repetir Contraseña"
            ></input>
            {sent === 1 ? (
              <p style={{ color: "red", fontWeight: "500" }}>
                Las contraseñas deben coincidir.
              </p>
            ) : (
              <>
                {sent === -1 ? (
                  <p style={{ color: "red", fontWeight: "500" }}>
                    Ha ocurrido un error reestableciendo la contraseña.
                  </p>
                ) : (
                  ""
                )}
              </>
            )}
            <button className="btn btn-primary mt-3 w-100">Confirmar</button>
          </form>
        </>
      ) : (
        <>
          <h3 className="mt-5 mb-5">
            Si olvidó su contraseña, por favor ingrese su email debajo.
          </h3>
          <form onSubmit={handleSubmit}>
            <strong>Email</strong>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
            ></input>
            {sent === 1 ? (
              <p style={{ color: "green", fontWeight: "500" }}>
                Email enviado, por favor revise su casilla de correo.
              </p>
            ) : (
              <>
                {sent === -1 ? (
                  <p style={{ color: "red", fontWeight: "500" }}>
                    Ha ocurrido un error enviando el email.
                  </p>
                ) : (
                  ""
                )}
              </>
            )}
            <button className="btn btn-primary mt-3 w-100">Confirmar</button>
          </form>
        </>
      )}
    </div>
  );
};

export default Passreset;
