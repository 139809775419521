import React, { useContext, useEffect, useState } from "react";
import styles from "./Register.module.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faEnvelope,
  faKey,
  faPhone,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import logowaven from "../../img/logowavenlow.png";
import loginback from "../../img/loginback.png";
import axios from "axios";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModalContext } from "../modal/ModalContext";
import { toast } from "react-toastify";
import logogesp from "../../img/logogesp.png";

const Register = () => {
  const [isWrong, setWrong] = useState(false);
  const [checking, setChecking] = useState(false);
  const { signIn, isLoggedIn } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (isLoggedIn) {
      navigate({ pathname: "dashboard", search: searchParams.toString() });
    }
  }, []);
  function handleSubmit(e) {
    setWrong(false);
    setChecking(true);
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("mode", 1);
    if (data.get("pass") != data.get("repass")) {
      setWrong(true);
      setChecking(false);
      return;
    }
    axios
      .post(process.env.REACT_APP_APIURL + "/auth.php", data)
      .then((res) => {
        if (res.data.status == "OK") {
          showModal(
            "Confirmado",
            "Se ha registrado, por favor chequee su casilla de correo electrónico para verificar su cuenta",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
          setWrong(false);
          setChecking(false);
        } else if (res.data.status == "YAUSER") {
          setWrong(false);
          setChecking(false);
          showModal(
            "Aviso",
            "Ya existe un usuario con ese email, por favor, inicie sesión."
          );
        } else {
          setWrong(false);
          toast.error("Ha ocurrido un error obteniendo datos del servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setWrong(false);
        toast.error("Ha ocurrido un error obteniendo datos del servidor");
        console.log(error);
      });
  }
  return (
    <div
      className={
        "vw-100 d-flex align-items-center justify-content-center p-md-3 " +
        styles.mainContainer
      }
    >
      <div className={"w-100 row align-items-center " + styles.loginContainer}>
        <div
          className={"d-none d-lg-flex  h-100 col-6 " + styles.brandingLarge}
          style={{
            backgroundImage: `url(${loginback})`,
          }}
        ></div>
        <div
          className={
            "col-12 col-lg-6 d-flex flex-column align-items-center pt-md-4 pt-5 " +
            styles.formContainer
          }
        >
          <img src={logogesp} className="w-50" style={{ maxWidth: "5cm" }} />
          <Form
            className={
              "d-flex align-items-center flex-column pt-2 pt-md-0 w-100 mt-md-4 mb-md-4 pb-3 " +
              styles.form
            }
            onSubmit={handleSubmit}
          >
            <h4 className="mt-2 mb-3 ">Registrarse</h4>

            <Form.Group>
              <Form.Label className={styles.textBold}>Email</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="email">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Email"
                  name="email"
                  type="email"
                  aria-label="Email"
                  aria-describedby="email"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label className={styles.textBold}>Nombre</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="name">
                  <FontAwesomeIcon icon={faUserCircle} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nombre"
                  name="name"
                  type="text"
                  aria-label="Name"
                  aria-describedby="name"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label className={styles.textBold}>Teléfono</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="tel">
                  <FontAwesomeIcon icon={faPhone} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Teléfono"
                  name="tel"
                  type="number"
                  aria-label="Telefono"
                  aria-describedby="telefono"
                  minLength={4}
                  required
                  maxLength={10}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label className={styles.textBold}>Contraseña</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="pass">
                  <FontAwesomeIcon icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Contraseña"
                  type="password"
                  name="pass"
                  aria-label="Contraseña"
                  aria-describedby="pass"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.textBold}>
                Repetir Contraseña
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="repass">
                  <FontAwesomeIcon icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Contraseña"
                  type="password"
                  name="repass"
                  aria-label="Contraseña"
                  aria-describedby="repass"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>
            <p
              className={styles.wrongPass}
              style={{ opacity: isWrong ? "1" : "0" }}
            >
              Las contraseñas deben coincidir
            </p>
            <Button
              variant="primary"
              disabled={checking}
              type="submit"
              className="w-100 mt-2 mb-2"
            >
              Registrarse
            </Button>
            <hr className={styles.hr} />
            <p className="mt-2">¿Ya tiene una cuenta?</p>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => {
                navigate("/");
              }}
              className="w-100"
            >
              Iniciar Sesión
            </Button>
          </Form>

          <div
            className={
              styles.brandingMobile +
              " d-flex flex-column align-items-center mt-1 w-100 justify-content-start"
            }
          >
            <span
              className={
                "d-flex flex-row align-items-center justify-content-center mt-5 mb-2"
              }
            >
              <FontAwesomeIcon icon={faCode} />{" "}
              <h6 className="ms-2 mb-0">Developed by</h6>{" "}
            </span>
            <img
              src={logowaven}
              alt="logo Waven Solutions"
              className={"w-25 mt-2 mb-5"}
              onClick={() =>
                (window.location.href = "http://www.wavensolutions.com")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
