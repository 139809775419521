import React, { useContext, useEffect } from "react";
import styles from "./Dashboard.module.css";
import Header from "./header/Header";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthProvider";

const Dashboard = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate({ pathname: "/", search: searchParams.toString() });
    }
  }, []);
  return (
    <>
      {isLoggedIn && (
        <div className={styles.mainContainer}>
          <Header />
          <div className={styles.mainView}>
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
