import React, { useContext, useEffect, useState } from "react";
import styles from "./ModificarTurno.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import {
  faAdd,
  faCalendar,
  faClose,
  faDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faPen,
  faPencil,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../../../load/LoadProvider";
import { ModalContext } from "../../../../../modal/ModalContext";
import { AuthContext } from "../../../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";

const ModificarTurno = () => {
  const [data, setData] = useState(null);
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const { turnid } = useParams();

  useEffect(() => {
    getTurno();
  }, []);

  const navigate = useNavigate();

  function getTurno() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=11&turno=" + turnid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          setData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error obteniendo el turno");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    data.append("mode", 11);
    data.append("turno", turnid);

    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Turno modificado correctamente");
          navigate(-1);
        } else {
          toast.error("Ha ocurrido un error agregando el espacio");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <>
      {data ? (
        <div className={"d-flex flex-column align-items-center pt-1"}>
          <hr className={"w-100"} />
          <h3>Modificar Turno</h3>
          <hr className={"w-25"} />
          <form
            onSubmit={handleSubmit}
            className={"d-flex flex-column align-items-center w-100"}
            style={{ maxWidth: "20cm" }}
          >
            <div className={"col-12 align-items-center p-3 row"}>
              <div className="col-12">
                <label className={"pb-2"}>
                  <FontAwesomeIcon icon={faPencil} className={"me-2"} />
                  <strong>Nombre:</strong>
                </label>
                <input
                  type="text"
                  className={"form-control mb-4"}
                  name={"nombre"}
                  maxLength={"50"}
                  minLength={"3"}
                  defaultValue={data.Nombre}
                  required
                />
                <label className={"pb-2"}>
                  <FontAwesomeIcon icon={faDollar} className={"me-2"} />
                  <strong>Precio:</strong>
                </label>
                <input
                  type="number"
                  className={"form-control mb-4"}
                  name={"precio"}
                  maxLength={"6"}
                  defaultValue={data.Precio}
                  required
                />
                <label className={"pb-2"}>
                  <FontAwesomeIcon icon={faDollar} className={"me-2"} />
                  <strong>Día:</strong>
                </label>
                <select
                  type="number"
                  className={"form-control mb-4"}
                  name={"dia"}
                  defaultValue={data.Dia}
                  required
                >
                  <option value="L">Lunes</option>
                  <option value="M">Martes</option>
                  <option value="X">Miércoles</option>
                  <option value="J">Jueves</option>
                  <option value="V">Viernes</option>
                  <option value="S">Sábado</option>
                  <option value="D">Domingo</option>
                </select>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-warning w-100 mt-5 p-3 m-2"
            >
              <FontAwesomeIcon icon={faPencil} className="me-2" />
              Modificar
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn btn-outline-danger w-100 mt-2 mb-4"
            >
              Cancelar
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ModificarTurno;
