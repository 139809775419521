import styles from "./App.module.css";
import "./App.scss";
import AuthProvider from "./components/Auth/AuthProvider";
import Login from "./components/login/Login";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./components/register/Register";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalProvider from "./components/modal/ModalProvider";
import Dashboard from "./dashboard/Dashboard";
import Misedificios from "./components/misedificios/Misedificios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Edificios from "./components/edificios/Edificios";
import Administraciones from "./components/administraciones/Administraciones";
import LoadProvider from "./load/LoadProvider";
import Reservar from "./components/reservar/Reservar";
import Misreservas from "./components/misreservas/Misreservas";
import Administrar from "./components/edificios/administrar/Administrar";
import Edificioinfo from "./components/edificios/administrar/edificioinfo/Edificioinfo";
import Usuarios from "./components/edificios/administrar/usuarios/Usuarios";
import Reservas from "./components/edificios/administrar/reservas/Reservas";
import Espacios from "./components/edificios/administrar/espacios/Espacios";
import CrearEspacio from "./components/edificios/administrar/espacios/crear/CrearEspacio";
import ModificarEspacio from "./components/edificios/administrar/espacios/modificar/ModificarEspacio";
import Turnos from "./components/edificios/administrar/espacios/turnos/Turnos";
import CrearTurno from "./components/edificios/administrar/espacios/turnos/crear/CrearTurno";
import ModificarTurno from "./components/edificios/administrar/espacios/turnos/modificar/ModificarTurno";
import AdministrarAdm from "./components/administraciones/administrar/AdministrarAdm";
import AdministracionInfo from "./components/administraciones/administrar/administracioninfo/AdministracionInfo";
import AdmEdificios from "./components/administraciones/administrar/edificios/AdmEdificios";
import CrearEdificio from "./components/administraciones/administrar/edificios/crear/CrearEdificio";
import CambiarContra from "./components/cambiarcontra/CambiarContra";
import Passreset from "./components/login/passreset/Passreset";
import Deudas from "./components/edificios/administrar/deudas/Deudas";
axios.defaults.withCredentials = true;

function App() {
  return (
    <ModalProvider>
      <div
        className={
          "d-flex vw-100 flex-column align-items-center justify-content-start " +
          styles.app
        }
      >
        <AuthProvider>
          <LoadProvider>
            <BrowserRouter>
              <Routes>
                <Route index element={<Login />} />
                <Route path="passreset" element={<Passreset />} />
                <Route path="register" element={<Register />} />
                <Route path="dashboard" element={<Dashboard />}>
                  <Route path="cambiarcontra" element={<CambiarContra />} />
                  <Route path="misedificios" element={<Misedificios />} />

                  <Route
                    index
                    element={
                      <h3 className={"pt-4 text-center"}>
                        Bienvenido/a a GESP!
                        <br />{" "}
                        <FontAwesomeIcon
                          className={styles.bounceAnim}
                          icon={faArrowUp}
                        />{" "}
                        Porfavor utilice el menú para comenzar
                      </h3>
                    }
                  />
                  <Route path="edificios" element={<Edificios />} />
                  <Route
                    path="administraciones"
                    element={<Administraciones />}
                  />
                  <Route
                    path="administraciones/administrar/:admid"
                    element={<AdministrarAdm />}
                  >
                    <Route path="info" element={<AdministracionInfo />} />
                    <Route path="edificios" element={<AdmEdificios />} />
                    <Route path="edificios/crear" element={<CrearEdificio />} />
                  </Route>

                  <Route
                    path="administraciones/administrar/:admid"
                    element={<AdministrarAdm />}
                  ></Route>

                  <Route
                    path="misedificios/reservar/:edifid"
                    element={<Reservar />}
                  />
                  <Route
                    path="misedificios/misreservas/:edifid/:edifnombre"
                    element={<Misreservas />}
                  />
                  <Route
                    path="edificios/administrar/:edifid"
                    element={<Administrar />}
                  >
                    <Route path="info" element={<Edificioinfo />} />
                    <Route path="usuarios" element={<Usuarios />} />
                    <Route path="deudas" element={<Deudas />} />
                    <Route path="reservas" element={<Reservas />} />
                    <Route path="espacios" element={<Espacios />} />
                    <Route path="espacios/crear" element={<CrearEspacio />} />
                    <Route
                      path="espacios/:espid/modificar"
                      element={<ModificarEspacio />}
                    />
                    <Route path="espacios/:espid/turnos" element={<Turnos />} />
                    <Route
                      path="espacios/:espid/turnos/crear"
                      element={<CrearTurno />}
                    />
                    <Route
                      path="espacios/:espid/turnos/:turnid/modificar"
                      element={<ModificarTurno />}
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>

            <ToastContainer />
          </LoadProvider>
        </AuthProvider>
      </div>
    </ModalProvider>
  );
}

export default App;
