import {
  filterFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadContext } from "../../../../load/LoadProvider";
import styles from "./Reservas.module.css";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faCalendar,
  faCheck,
  faClock,
  faClose,
  faDownload,
  faEye,
  faPencil,
  faSearch,
  faShop,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";

const Reservas = () => {
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadContext);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  let inicioMes = new Date();
  inicioMes.setDate(1);

  let finMes = new Date();
  finMes.setDate(1);
  finMes.setMonth(finMes.getMonth() + 1);
  finMes.setDate(finMes.getDate() - 1);

  const [fechaDesde, setFechaDesde] = useState(
    inicioMes.toISOString().substring(0, 10)
  );
  const [fechaHasta, setFechaHasta] = useState(
    finMes.toISOString().substring(0, 10)
  );

  const [espacios, setEspacios] = useState([]);
  const [selectedEspacio, setSelectedEspacio] = useState("todos");
  const [selectedEstado, setSelectedEstado] = useState("todos");
  const [modifyModalDisplay, setModifyModalDisplay] = useState({
    display: false,
    reserva: null,
    mode: null,
  });
  const motivoField = useRef();
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 8, //default page size
  });
  const [filtering, setFiltering] = useState("");
  const { edifid } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({
    Reserva_ID: false,
    Moneda: false,
  });
  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    updateData();
  }, [fechaDesde, fechaHasta, selectedEspacio, selectedEstado]);
  useEffect(() => {
    if (modifyModalDisplay.display && modifyModalDisplay.mode == 0) {
      //modo cancelar
    }
  }, [modifyModalDisplay]);
  const conlumnDef = [
    {
      header: "Reserva_ID",
      accessorKey: "Reserva_ID",
    },
    {
      header: "Moneda",
      accessorKey: "Moneda",
    },
    {
      header: "Usuario",
      accessorKey: "Usuario",
    },
    {
      header: "Unidad",
      accessorKey: "Unidad",
    },
    {
      header: "Espacio",
      accessorKey: "Espacio",
    },
    {
      header: "Total",
      accessorKey: "Total",
      cell: ({ row, getValue }) => {
        return row.original.Moneda + getValue();
      },
    },
    {
      header: "Turno",
      accessorKey: "Turno",
    },
    {
      header: "Día",
      accessorKey: "Dia",
    },
    {
      header: "Estado",
      accessorKey: "Estado",
      cell: ({ row }) => (
        <div>
          {row.original.Estado == 0 ? (
            <div style={{ color: "orange" }}>
              <strong>Pendiente</strong>
            </div>
          ) : row.original.Estado == 1 ? (
            <div style={{ color: "rgb(50, 180, 50)" }}>
              <strong>Confirmada</strong>
            </div>
          ) : row.original.Estado == 2 ? (
            <div style={{ color: "red" }}>
              <strong>Rechazada</strong>
            </div>
          ) : (
            <div style={{ color: "rgb(150, 0,0)" }}>
              <strong>Cancelada</strong>
            </div>
          )}
        </div>
      ),
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          {row.original.Estado == 0 ? (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <strong>Confirmar</strong>
                  </Tooltip>
                }
              >
                <Button
                  className={"btn btn-success ms-3"}
                  onClick={() => {
                    handleConfirm(row.original.Reserva_ID);
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <strong>Rechazar</strong>
                  </Tooltip>
                }
              >
                <Button
                  className={"btn btn-danger ms-3"}
                  onClick={() => {
                    setModifyModalDisplay((prev) => {
                      return {
                        display: true,
                        reserva: row.original.Reserva_ID,
                        mode: 0,
                      };
                    });
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Ver Reserva</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-warning ms-3"}
              onClick={() => {
                setLoading(true);
                setModifyModalDisplay((prev) => {
                  return {
                    display: false,
                    //reserva: row.original.Reserva_ID,
                    reserva: null,
                    mode: 1,
                  };
                });
                getReserva(row.original.Reserva_ID);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: conlumnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    globalFilterFn: "includesString",
    state: {
      pagination,
      globalFilter: filtering,
      columnVisibility,
    },
  });
  function updateData() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/reservas.php?mode=4&edif=" + edifid)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setEspacios(res.data.espacios);

          res.data.data = res.data.data.filter((reserva) => {
            return (
              fechaDesde == "" || new Date(reserva.DiaC) >= new Date(fechaDesde)
            );
          });
          res.data.data = res.data.data.filter((reserva) => {
            return (
              fechaHasta == "" || new Date(reserva.DiaC) <= new Date(fechaHasta)
            );
          });
          if (selectedEspacio != "todos") {
            res.data.data = res.data.data.filter(
              (reserva) => reserva.Espacio == selectedEspacio
            );
          }
          if (selectedEstado != "todos") {
            res.data.data = res.data.data.filter(
              (reserva) => reserva.Estado == selectedEstado
            );
          }

          setData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function getReserva(reserva) {
    axios
      .get(
        process.env.REACT_APP_APIURL + "/reservas.php?mode=1&reserva=" + reserva
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setModifyModalDisplay({
            display: true,
            reserva: res.data.data,
            mode: 1,
          });
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleInforme(e) {
    window.open(
      process.env.REACT_APP_APIURL +
        "/edificios.php?mode=13&desde=" +
        fechaDesde +
        "&hasta=" +
        fechaHasta +
        "&edif=" +
        edifid +
        "&estado=" +
        selectedEstado +
        "&espacio=" +
        selectedEspacio,
      "_blank"
    );
  }
  function handleConfirm(reserva) {
    showModal(
      "Confirmación",
      "¿Está seguro/a que desea confirmar esta reserva?",
      () => {
        setLoading(true);
        let data = new FormData();
        data.append("mode", 2);
        data.append("reserva", reserva);

        axios
          .post(process.env.REACT_APP_APIURL + "/reservas.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Reserva confirmada correctamente");
              updateData();
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }
  function handleCancel(e) {
    e.preventDefault();
    setLoading(true);
    let data = new FormData(e.target);
    data.append("reserva", modifyModalDisplay.reserva);
    data.append("mode", 3);
    axios
      .post(process.env.REACT_APP_APIURL + "/reservas.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Reserva rechazada correctamente");
          updateData();
          setModifyModalDisplay((prev) => {
            return { display: false, reserva: null, mode: null };
          });
        } else if (res.data.status == "NOMODIF") {
          toast.error("No se modificó el usuario");
        } else {
          toast.error("Ha ocurrido un error modificando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center pt-4 ">
      {modifyModalDisplay.display ? (
        <form
          className={styles.addModalContainer}
          onSubmit={(e) => {
            if (modifyModalDisplay.mode == 0) {
              handleCancel(e);
            } else {
              setModifyModalDisplay((prev) => {
                return { display: false, reserva: null, mode: null };
              });
            }
          }}
        >
          {" "}
          <div className={"rounded-5 " + styles.addModal}>
            <div
              className={"w-100 rounded-top-5 d-flex flex-row " + styles.topBar}
            >
              <h2>
                {modifyModalDisplay.mode == 0
                  ? "Cancelar reserva"
                  : "Ver reserva"}
              </h2>
            </div>

            <div
              className={
                styles.mainText +
                " d-flex flex-column align-item-center justify-content-center w-50"
              }
            >
              <div className="d-flex flex-column w-100 align-items-center">
                {modifyModalDisplay.mode == 0 ? (
                  <>
                    <label>
                      <strong>Motivo de cancelación</strong>
                    </label>
                    <input
                      type="text"
                      name="motivo"
                      maxLength={100}
                      ref={motivoField}
                      className="form-control mb-3"
                    />
                    <i style={{ fontSize: "1rem" }}>
                      *Se le avisará al usuario por email indicando el motivo.
                    </i>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        "w-100 d-flex flex-column justify-content-center"
                      }
                      style={{ fontSize: "1rem" }}
                    >
                      <div className="w-100 d-flex flex-column flex-md-row">
                        <span
                          className="w-100 d-flex flex-column align-items-center "
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <span className="">
                            {" "}
                            <FontAwesomeIcon className="m-1" icon={faShop} />
                            <strong className="m-2">Espacio: </strong>
                          </span>

                          {modifyModalDisplay.reserva.Espacio}
                        </span>
                        <span className="w-100 d-flex flex-column align-items-center">
                          <span>
                            <FontAwesomeIcon
                              className="m-1"
                              icon={faCalendar}
                            />
                            <strong className="m-2">Día: </strong>
                          </span>
                          {modifyModalDisplay.reserva.Dia}
                        </span>
                        <span className="w-100 d-flex flex-column align-items-center">
                          <span>
                            <FontAwesomeIcon className="m-1" icon={faClock} />
                            <strong className="m-2">Turno: </strong>
                          </span>
                          {modifyModalDisplay.reserva.Turno}
                        </span>
                        <span className="w-100 d-flex flex-column align-items-center">
                          <span style={{ whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon className="m-1" icon={faCheck} />
                            <strong className="m-2">Estado: </strong>
                          </span>
                          {modifyModalDisplay.reserva.Estado}
                        </span>
                      </div>
                      <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3">
                        {modifyModalDisplay.reserva.extras ? (
                          <>
                            <h5>Extras</h5>
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: "rgb(240,240,240)",
                                maxHeight: "3cm",
                                overflowY: "auto",
                              }}
                            >
                              <table
                                className="rounded-2 p-4"
                                style={{
                                  backgroundColor: "rgb(240,240,240)",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Extra</th>
                                    <th className="ps-3">Costo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {modifyModalDisplay.reserva.extras.map(
                                    (ext) => {
                                      return (
                                        <tr>
                                          <td>{ext.Nombre}</td>
                                          <td className="ps-3">
                                            {modifyModalDisplay.reserva.Moneda +
                                              ext.Costo}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <hr></hr>
                        <h4>
                          Total:{" "}
                          {modifyModalDisplay.reserva.Moneda +
                            modifyModalDisplay.reserva.Total}
                        </h4>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <button type="submit" className="btn btn-success">
                {" "}
                Aceptar
              </button>
              <button
                type="button"
                onClick={() => {
                  setModifyModalDisplay((prev) => {
                    return { display: false, reserva: null, mode: null };
                  });
                }}
                className="btn btn-outline-danger"
              >
                {" "}
                Cancelar
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
      <span
        className="w-100 mb-4 d-flex align-items-center flex-column flex-md-row justify-content-center"
        style={{ maxWidth: "20cm" }}
      >
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={faSearch} className="me-2 ms-3" />
          <input
            type="text"
            className="form-control"
            placeholder="Buscar"
            onChange={(e) => setFiltering(e.target.value)}
            value={filtering}
          />
        </div>
        <div className="vr ms-3 me-3 d-none d-md-block" />
        <div className="d-flex flex-row mt-3 align-items-center">
          <FontAwesomeIcon icon={faCalendar} className="me-2 ms-3" />
          <strong className="me-2">Desde: </strong>
          <input
            type="date"
            className="form-control me-md-3"
            onChange={(e) => setFechaDesde(e.target.value)}
            max={fechaHasta}
            value={fechaDesde}
          />
        </div>
        <div className="d-flex flex-row mt-3 align-items-center">
          <FontAwesomeIcon icon={faCalendar} className="me-2 ms-3" />
          <strong className="me-2">Hasta: </strong>
          <input
            type="date"
            className="form-control"
            onChange={(e) => setFechaHasta(e.target.value)}
            value={fechaHasta}
            min={fechaDesde}
          />
        </div>
      </span>
      <span
        className="w-100 mb-4 d-flex align-items-center flex-column flex-md-row justify-content-center"
        style={{ maxWidth: "20cm" }}
      >
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={faBuilding} className="me-2 ms-3" />
          <strong className="me-2">Espacio: </strong>
          <select
            className="form-control m-2"
            style={{ maxWidth: "5cm" }}
            onChange={(e) => setSelectedEspacio(e.target.value)}
            value={selectedEspacio}
          >
            <option value={"todos"}>Todos</option>
            {espacios &&
              espacios.map((esp) => (
                <option value={esp.Nombre}>{esp.Nombre}</option>
              ))}
          </select>
        </div>
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={faCheck} className="me-2 ms-3" />
          <strong className="me-2">Estado: </strong>
          <select
            className="form-control m-2"
            style={{ maxWidth: "5cm" }}
            onChange={(e) => setSelectedEstado(e.target.value)}
            value={selectedEstado}
          >
            <option value={"todos"}>Todas</option>
            <option value={0}>Pendientes</option>
            <option value={1}>Confirmadas</option>
            <option value={2}>Rechazadas</option>
            <option value={3}>Canceladas</option>
          </select>
        </div>
        <button
          className="btn btn-outline-success m-2 d-flex flex-row"
          onClick={handleInforme}
          disabled={data.length == 0}
        >
          <FontAwesomeIcon icon={faDownload} className="m-1" />
          Informe
        </button>
      </span>
      <div
        className={"w-100 " + styles.tableContainer}
        style={{ overflowX: "auto" }}
      >
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <span
          className={
            "d-flex flex-row align-items-center w-100 justify-content-center"
          }
        >
          <button
            onClick={tableInstance.previousPage}
            disabled={!tableInstance.getCanPreviousPage()}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>{" "}
          <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={tableInstance.nextPage}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default Reservas;
