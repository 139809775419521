import { faKey, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { LoadContext } from "../../load/LoadProvider";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const CambiarContra = () => {
  const [passWrong, setPassWrong] = useState(false);
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  function handleSubmit(e) {
    console.log(passWrong);
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    data.append("mode", 3);
    if (data.get("pass") !== data.get("repass")) {
      setPassWrong(true);
      setLoading(false);
      return;
    }

    axios
      .post(process.env.REACT_APP_APIURL + "/auth.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Contraseña cambiada correctamente");
          navigate("/dashboard/misedificios");
        } else {
          toast.error("Ha ocurrido un error cambiando la contraseña");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }

  return (
    <div className="d-flex flex-column pt-3">
      <h2>Cambiar Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <label className={"pb-2 pt-4"}>
          <FontAwesomeIcon icon={faKey} className={"me-2"} />
          <strong>Nueva Contraseña:</strong>
        </label>
        <input
          type="password"
          className={"form-control mb-4"}
          name={"pass"}
          maxLength={"15"}
          minLength={"3"}
          required
        />
        <label className={"pb-2"}>
          <FontAwesomeIcon icon={faKey} className={"me-2"} />
          <strong>Repetir Contraseña:</strong>
        </label>
        <input
          type="password"
          className={"form-control mb-4"}
          name={"repass"}
          maxLength={"15"}
          minLength={"3"}
          required
        />
        <label
          className="d-flex flex-row justify-content-center pb-4"
          style={{
            color: "red",
            fontWeight: "500",
            visibility: passWrong ? "visible" : "hidden",
          }}
        >
          Las contraseñas deben coincidir
        </label>
        <button type="submit" className="w-100 btn btn-success">
          Cambiar contraseña
        </button>
      </form>
    </div>
  );
};

export default CambiarContra;
