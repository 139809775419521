import {
  faArrowLeft,
  faBuilding,
  faBuildingColumns,
  faClock,
  faClose,
  faGear,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  NavLink,
  Outlet,
  useParams,
} from "react-router-dom";
import styles from "./AdministrarAdm.module.css";
import { LoadContext } from "../../../load/LoadProvider";
import axios from "axios";
import { AuthContext } from "../../Auth/AuthProvider";
import { ModalContext } from "../../modal/ModalContext";
import { toast } from "react-toastify";

const AdministrarAdm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [admData, setAdmData] = useState(null);
  const { setLoading } = useContext(LoadContext);
  const { signOut } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const { admid } = useParams();
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL +
          "/administraciones.php?mode=2&adm=" +
          admid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          setAdmData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.request && error.request.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(error);
        }
      });
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className="w-100 d-flex justify-content-start mb-3">
          {" "}
          <button
            onClick={() => {
              navigate("/dashboard/administraciones");
            }}
            className={
              "btn btn-outline-danger text-center d-flex flex-row align-items-center justify-content-center me-3"
            }
          >
            {" "}
            <FontAwesomeIcon className={""} icon={faArrowLeft} />
            <FontAwesomeIcon className={"me-3"} icon={faClose} />
            Administraciones
          </button>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className={
              "btn btn-outline-primary text-center d-flex flex-row align-items-center justify-content-center"
            }
            style={{
              width: "20%",
              maxWidth: "5cm",
            }}
          >
            <FontAwesomeIcon className={"me-3"} icon={faArrowLeft} />
            Atrás
          </button>
        </div>
        <h3 className={""}>
          <FontAwesomeIcon className={"me-2"} icon={faBuildingColumns} />
          Gestionar Administración {admData ? admData.Nombre : ""}
        </h3>
      </div>
      <div className={styles.topBar}>
        <NavLink
          to={"info"}
          className={({ isActive }) => {
            return (
              (isActive ? styles.NavLinkActive : "") + " " + styles.NavLink
            );
          }}
        >
          <FontAwesomeIcon icon={faGear} className={"me-2"} />
          Información General
        </NavLink>
        <NavLink
          to={"edificios"}
          className={({ isActive }) => {
            return (
              (isActive ? styles.NavLinkActive : "") + " " + styles.NavLink
            );
          }}
        >
          <FontAwesomeIcon icon={faBuilding} className={"me-2"} />
          Edificios
        </NavLink>
      </div>
      <div className={"w-100"}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdministrarAdm;
