import React, { useContext, useEffect, useState } from "react";
import styles from "./Header.module.css";
import { AuthContext } from "../../components/Auth/AuthProvider";
import logo from "../../img/logogesp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBuildingCircleCheck,
  faBuildingColumns,
  faClose,
  faKey,
  faList,
  faSignOut,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadContext } from "../../load/LoadProvider";
import { ModalContext } from "../../components/modal/ModalContext";

const Header = () => {
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [offExpanded, setOffExpanded] = useState(false);
  const [offClosing, setOffClosing] = useState(false);
  const [offUserExpanded, setOffUserExpanded] = useState(false);
  const [offUserClosing, setOffUserClosing] = useState(false);
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const [headerButtons, setHeaderButtons] = useState({
    edificios: "0",
    administraciones: "0",
  });
  useEffect(() => {
    setLoading(true);
    //chequear botones
    axios
      .get(process.env.REACT_APP_APIURL + "/auth.php?mode=1")
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          setHeaderButtons((prev) => {
            return {
              ...prev,
              edificios: res.data.data.edificios,
              administraciones: res.data.data.administraciones,
            };
          });
        } else {
          toast.error("Ha ocurrido un error conectando al servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }, []);
  function handleLogout() {
    signOut();
    navigate({
      pathname: "/",
      search: searchParams.toString(),
    });
  }
  function closeOff() {
    setOffClosing(true);
    setTimeout(() => {
      setOffExpanded(false);
    }, 450);
  }
  function openOff() {
    setOffClosing(false);
    setOffExpanded(true);
  }

  function closeUserOff() {
    setOffUserClosing(true);
    setTimeout(() => {
      setOffUserExpanded(false);
    }, 450);
  }
  function openUserOff() {
    setOffUserClosing(false);
    setOffUserExpanded(true);
  }
  return (
    <>
      {offExpanded && (
        <div
          className={
            styles.offCanvasContainer +
            " " +
            (offClosing ? styles.hideOffCanvasContainer : "")
          }
        >
          <div
            className={
              "" +
              styles.offCanvas +
              " " +
              (offClosing ? styles.hideOffCanvas : "")
            }
          >
            <span className={styles.offCanvasTitle}>
              <h2 className="mt-4 ms-3 mb-4 col-8">Menú</h2>
              <FontAwesomeIcon
                className="me-3 col-3"
                style={{ fontSize: "1.3rem" }}
                icon={faClose}
                onClick={closeOff}
              />
            </span>
            <NavLink
              className={({ isActive }) => {
                return (
                  (isActive ? styles.linkActive + " " : " ") +
                  styles.link +
                  " p-3"
                );
              }}
              onClick={closeOff}
              to="misedificios"
            >
              <FontAwesomeIcon className="me-3" icon={faBuilding} />
              Mis Edificios
            </NavLink>
            {headerButtons.edificios === "1" && (
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? styles.linkActive + " " : " ") +
                    styles.link +
                    " p-3"
                  );
                }}
                to="edificios"
                onClick={closeOff}
              >
                <FontAwesomeIcon
                  className="me-3"
                  icon={faBuildingCircleCheck}
                />
                Gestionar Edificios
              </NavLink>
            )}

            {headerButtons.administraciones === "1" && (
              <NavLink
                className={({ isActive }) => {
                  return (
                    (isActive ? styles.linkActive + " " : " ") +
                    styles.link +
                    " p-3"
                  );
                }}
                to="administraciones"
                onClick={closeOff}
              >
                <FontAwesomeIcon className="me-3" icon={faBuildingColumns} />
                Administraciones
              </NavLink>
            )}
          </div>
        </div>
      )}
      {offUserExpanded && (
        <div
          className={
            styles.offUserCanvasContainer +
            " " +
            (offUserClosing ? styles.hideOffUserCanvasContainer : "")
          }
        >
          <div
            className={
              "" +
              styles.offUserCanvas +
              " " +
              (offUserClosing ? styles.hideOffUserCanvas : "")
            }
          >
            <span className={styles.offUserCanvasTitle}>
              <h2 className="mt-4 ms-3  col-8">Usuario</h2>

              <FontAwesomeIcon
                className="me-3 col-3"
                style={{ fontSize: "1.3rem" }}
                icon={faClose}
                onClick={closeUserOff}
              />
            </span>
            <hr />
            <p
              className={"p-2 "}
              style={{ fontSize: "1rem", fontWeight: "500" }}
            >
              <FontAwesomeIcon icon={faUserCircle} />
              <br />
              {localStorage.getItem("authName")}
              <br />
              {localStorage.getItem("authEmail")}
            </p>
            <NavLink
              className={({ isActive }) => {
                return (
                  (isActive ? styles.linkActive + " " : " ") +
                  styles.link +
                  " p-3"
                );
              }}
              to="cambiarcontra"
            >
              <FontAwesomeIcon className="me-3" icon={faKey} />
              Cambiar Contraseña
            </NavLink>
            <div
              className={styles.link + " p-3"}
              onClick={handleLogout}
              style={{ color: "red" }}
            >
              <FontAwesomeIcon className="me-3" icon={faSignOut} />
              Cerrar Sesión
            </div>
          </div>
        </div>
      )}

      <div
        className={
          "w-100 d-flex flex-row align-items-center p-4 d-lg-none " +
          styles.header
        }
      >
        <div className={"col-4"}>
          <FontAwesomeIcon
            icon={faList}
            style={{ fontSize: "1.2rem" }}
            onClick={openOff}
          />
        </div>
        <div className={"col-4"}>
          <img
            className={"img-fluid w-100"}
            style={{ maxWidth: "150px" }}
            src={logo}
          />
        </div>
        <div className={"col-4 d-flex flex-row justify-content-end pe-2"}>
          <FontAwesomeIcon
            icon={faUserCircle}
            onClick={openUserOff}
            style={{ fontSize: "1.2rem" }}
          />
        </div>
      </div>
      <div
        className={
          "vw-100 d-flex flex-row align-items-center d-lg-flex d-none " +
          styles.header
        }
      >
        <div className={"col-2 d-flex p-3 justify-content-center"}>
          <img
            className={"img-fluid w-50"}
            style={{ minWidth: "100px", maxWidth: "5cm" }}
            src={logo}
          />
        </div>
        <div className={"col-6 d-flex h-100 flex-row align-items-center"}>
          <NavLink
            className={({ isActive }) => {
              return (
                (isActive ? styles.mdLinkActive : "") + " p-4 " + styles.mdLink
              );
            }}
            to="misedificios"
          >
            <FontAwesomeIcon className="me-3" icon={faBuilding} />
            Mis Edificios
          </NavLink>
          {headerButtons.edificios === "1" && (
            <NavLink
              className={({ isActive }) => {
                return (
                  (isActive ? styles.mdLinkActive : "") +
                  " p-4 " +
                  styles.mdLink
                );
              }}
              to="edificios"
            >
              <FontAwesomeIcon className="me-3" icon={faBuildingCircleCheck} />
              Gestionar Edificios
            </NavLink>
          )}
          {headerButtons.administraciones === "1" && (
            <NavLink
              className={({ isActive }) => {
                return (
                  (isActive ? styles.mdLinkActive : "") +
                  " p-4 " +
                  styles.mdLink
                );
              }}
              to="administraciones"
            >
              <FontAwesomeIcon className="me-3" icon={faBuildingColumns} />
              Administraciones
            </NavLink>
          )}
        </div>
        <div className={"col-3 d-flex flex-row align-items-center"}>
          <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: "1.2rem" }} />
          <div className={"h-100 d-flex flex-column ps-3"}>
            <h5>{localStorage.getItem("authName")}</h5>
            <p
              className="h6"
              style={{
                textWrap: "wrap",
                maxWidth: "5cm",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {localStorage.getItem("authEmail")}
            </p>
          </div>
          <NavLink
            className={({ isActive }) => {
              return (
                (isActive ? styles.linkActive + " " : " ") +
                styles.mdLink +
                " p-3 ms-2 rounded-4"
              );
            }}
            to="cambiarcontra"
          >
            <FontAwesomeIcon className="me-3" icon={faKey} />
          </NavLink>
          <div
            className={"p-3 ms-3 " + styles.logout}
            onClick={handleLogout}
            style={{ fontSize: "1.2rem" }}
          >
            <FontAwesomeIcon className="me-3" icon={faSignOut} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
