import {
  filterFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadContext } from "../../../../load/LoadProvider";
import styles from "./Usuarios.module.css";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faPencil,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";

const Usuarios = () => {
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadContext);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modifyModalDisplay, setModifyModalDisplay] = useState({
    display: false,
    code: null,
    mode: null,
  });
  const unidadField = useRef();
  const tipoField = useRef();
  const adminField = useRef();
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [filtering, setFiltering] = useState("");
  const { edifid } = useParams();
  useEffect(() => {
    updateData();
  }, []);
  useEffect(() => {
    if (modifyModalDisplay.display && modifyModalDisplay.mode == 1) {
      //modo modificar
      setLoading(true);
      axios
        .get(
          process.env.REACT_APP_APIURL +
            `/edificios.php?mode=7&edif=${edifid}&codigo=${modifyModalDisplay.code}`
        )
        .then((res) => {
          setLoading(false);
          if (res.data.status == "OK") {
            unidadField.current.value = res.data.data.Unidad;
            tipoField.current.value = res.data.data.Tipo;
            adminField.current.checked = res.data.data.Administra == "1";
          } else {
            toast.error("Ha ocurrido un error actualizando");
            console.log(res);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            signOut();
            showModal(
              "Error de autenticación",
              "Su sesión ha expirado, por favor vuelva a iniciar sesión",
              () => {
                navigate("/");
              },
              () => {
                navigate("/");
              }
            );
          } else {
            toast.error("Ha ocurrido un error actualizando");
            console.log(error);
          }
        });
    }
  }, [modifyModalDisplay]);
  const conlumnDef = [
    {
      header: "Código",
      accessorKey: "Codigo",
    },
    {
      header: "Usuario",
      accessorKey: "Usuario",
      accessorFn: (row) => {
        if (row.Usuario == null) {
          return "NA";
        } else {
          return row.Usuario;
        }
      },
    },
    {
      header: "Tipo",
      accessorKey: "Tipo",
    },
    {
      header: "Unidad",
      accessorKey: "Unidad",
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Modificar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-warning ms-3"}
              onClick={() => {
                setModifyModalDisplay((prev) => {
                  return { display: true, code: row.original.Codigo, mode: 1 };
                });
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Eliminar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-danger ms-3"}
              onClick={() => {
                handleDelete(row.original.Codigo);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: conlumnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    globalFilterFn: "includesString",
    state: {
      pagination,
      globalFilter: filtering,
    },
  });
  function updateData() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=6&edif=" + edifid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleDelete(code) {
    showModal(
      "Eliminar",
      "¿Está seguro/a que desea eliminar este usuario? (No se puede deshacer)",
      () => {
        setLoading(true);
        let data = new FormData();
        data.append("codigo", code);
        data.append("edif", edifid);
        data.append("mode", 5);

        axios
          .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Usuario eliminado correctamente");
              if (res.data.yo) {
                navigate("/dashboard/misedificios");
              } else {
                updateData();
              }
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }
  function handleModify(e) {
    e.preventDefault();
    setLoading(true);
    let data = new FormData(e.target);
    data.append("codigo", modifyModalDisplay.code);
    data.append("edif", edifid);
    data.append("mode", 3);
    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Usuario modificado correctamente");
          updateData();
          setModifyModalDisplay((prev) => {
            return { display: false, code: null, mode: null };
          });
        } else if (res.data.status == "NOMODIF") {
          toast.error("No se modificó el usuario");
        } else {
          toast.error("Ha ocurrido un error modificando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleAdd(e) {
    e.preventDefault();
    setLoading(true);
    let data = new FormData(e.target);
    data.append("edif", edifid);
    data.append("mode", 4);
    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        setModifyModalDisplay((prev) => {
          return { display: false, code: null, mode: null };
        });
        if (res.data.status == "OK") {
          showModal(
            "Correcto",
            "El usuario fue agregado correctamente con el código: " +
              res.data.codigo
          );
          updateData();
        } else if (res.data.status == "NOMODIF") {
          toast.error("No se modificó el usuario");
        } else {
          toast.error("Ha ocurrido un error modificando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center pt-4 ">
      {modifyModalDisplay.display ? (
        <form
          className={styles.addModalContainer}
          onSubmit={(e) => {
            if (modifyModalDisplay.mode == 1) {
              handleModify(e);
            } else if (modifyModalDisplay.mode == 0) {
              handleAdd(e);
            }
          }}
        >
          {" "}
          <div className={"rounded-5 " + styles.addModal}>
            <div
              className={"w-100 rounded-top-5 d-flex flex-row " + styles.topBar}
            >
              <h2>
                {modifyModalDisplay.mode == 0
                  ? "Agregar Usuario a Edificio"
                  : "Modificar Usuario de Edificio"}
              </h2>
            </div>

            <div
              className={
                styles.mainText +
                " d-flex flex-column align-item-center justify-content-center w-50"
              }
            >
              <div className="d-flex flex-column align-items-center">
                <label>
                  <strong>Unidad</strong>
                </label>
                <input
                  type="text"
                  name="unidad"
                  ref={unidadField}
                  className="form-control mb-3"
                />
                <label>
                  <strong>Tipo</strong>
                </label>
                <select className="form-select" name="tipo" ref={tipoField}>
                  <option value="I">Inquilino</option>
                  <option value="P">Propietario</option>
                </select>
                <label>
                  <strong>Administrador</strong>
                </label>
                <input
                  type="checkbox"
                  ref={adminField}
                  name="admin"
                  value={1}
                />
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <button type="submit" className="btn btn-success">
                {" "}
                Agregar
              </button>
              <button
                type="button"
                onClick={() => {
                  setModifyModalDisplay((prev) => {
                    return { display: false, code: null, mode: null };
                  });
                }}
                className="btn btn-outline-danger"
              >
                {" "}
                Cancelar
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}
      <span
        className="w-100 mb-4 d-flex align-items-center flex-row justify-content-center"
        style={{ maxWidth: "10cm" }}
      >
        <button
          className={"btn btn-success"}
          onClick={() => {
            setModifyModalDisplay((prev) => {
              return { display: true, mode: 0, code: null };
            });
          }}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
        <div className="vr me-3 ms-3"></div>
        <FontAwesomeIcon icon={faSearch} className="me-4" />
        <input
          type="text"
          className="form-control"
          placeholder="Buscar"
          onChange={(e) => setFiltering(e.target.value)}
          value={filtering}
        />
      </span>
      <div
        className={"w-100 " + styles.tableContainer}
        style={{ overflowX: "auto" }}
      >
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.getValue() == "NA" &&
                    cell.column.columnDef.accessorKey == "Usuario" ? (
                      <strong style={{ color: "rgb(200, 100, 100)" }}>
                        <i>Aún no asignado</i>
                      </strong>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <span
          className={
            "d-flex flex-row align-items-center w-100 justify-content-center"
          }
        >
          <button
            onClick={tableInstance.previousPage}
            disabled={!tableInstance.getCanPreviousPage()}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>{" "}
          <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={tableInstance.nextPage}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default Usuarios;
