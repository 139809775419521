import {
  filterFns,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadContext } from "../../../../load/LoadProvider";
import styles from "./Espacios.module.css";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faClock,
  faClockFour,
  faPencil,
  faSearch,
  faTrash,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";

const Espacios = () => {
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadContext);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [filtering, setFiltering] = useState("");
  const { edifid } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({
    Esp_ID: false,
  });
  useEffect(() => {
    updateData();
  }, []);
  const conlumnDef = [
    {
      header: "Esp_ID",
      accessorKey: "Esp_ID",
    },
    {
      header: "Nombre",
      accessorKey: "Nombre",
    },
    {
      header: "Extras",
      accessorKey: "Extras",
    },
    {
      header: "Turnos",
      accessorKey: "Turnos",
    },
    {
      header: "Cupo",
      accessorKey: "Cupo",
    },
    {
      header: "Permisos",
      accessorKey: "Permisos",
    },
    {
      header: "Teléfono Mantenimiento",
      accessorKey: "Telefono",
    },
    {
      header: "Email Mantenimiento",
      accessorKey: "Email",
    },
    {
      header: "Moneda",
      accessorKey: "Moneda",
    },

    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Modificar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-warning ms-3"}
              onClick={() => {
                navigate(row.original.Esp_ID + "/modificar");
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Turnos</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-success ms-3"}
              onClick={() => {
                navigate(row.original.Esp_ID + "/turnos");
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faClock} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                <strong>Eliminar</strong>
              </Tooltip>
            }
          >
            <Button
              className={"btn btn-danger ms-3"}
              onClick={() => {
                handleDelete(row.original.Esp_ID);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: conlumnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    globalFilterFn: "includesString",
    state: {
      pagination,
      globalFilter: filtering,
      columnVisibility,
    },
  });
  function updateData() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=8&edif=" + edifid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleDelete(id) {
    showModal(
      "Eliminar",
      "¿Está seguro/a que desea eliminar este espacio? (No se puede deshacer)",
      () => {
        setLoading(true);
        let data = new FormData();
        data.append("espacio", id);
        data.append("mode", 6);

        axios
          .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Espacio eliminado correctamente");
              updateData();
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center pt-4 ">
      <span
        className="w-100 mb-4 d-flex align-items-center flex-row justify-content-center"
        style={{ maxWidth: "10cm" }}
      >
        <button
          className={"btn btn-success"}
          onClick={() => {
            navigate("crear");
          }}
        >
          <FontAwesomeIcon icon={faAdd} />
        </button>
        <div className="vr me-3 ms-3"></div>
        <FontAwesomeIcon icon={faSearch} className="me-4" />
        <input
          type="text"
          className="form-control"
          placeholder="Buscar"
          onChange={(e) => setFiltering(e.target.value)}
          value={filtering}
        />
      </span>
      <div
        className={"w-100 " + styles.tableContainer}
        style={{ overflowX: "auto" }}
      >
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <span
          className={
            "d-flex flex-row align-items-center w-100 justify-content-center"
          }
        >
          <button
            onClick={tableInstance.previousPage}
            disabled={!tableInstance.getCanPreviousPage()}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>{" "}
          <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
          <button
            disabled={!tableInstance.getCanNextPage()}
            onClick={tableInstance.nextPage}
            className={"btn btn-outline-primary"}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </span>
      </div>
    </div>
  );
};

export default Espacios;
