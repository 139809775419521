import React, { useContext, useEffect, useState } from "react";
import styles from "./Login.module.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleHalfStroke,
  faClockRotateLeft,
  faCode,
  faEnvelope,
  faKey,
  faTruckLoading,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import logowaven from "../../img/logowavenlow.png";
import loginback from "../../img/loginback.png";
import axios from "axios";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import Dashboard from "../../dashboard/Dashboard";
import { LoadContext } from "../../load/LoadProvider";
import logogesp from "../../img/logogesp.png";

const Login = () => {
  const [isWrong, setWrong] = useState(false);
  const [checking, setChecking] = useState(false);
  const { signIn, isLoggedIn } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const { setLoading } = useContext(LoadContext);
  const [loadLogin, setLoadLogin] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("admid")) {
      localStorage.setItem("admid", searchParams.get("admid"));
    }
    if (isLoggedIn) {
      navigate("dashboard");
    }
    if (
      searchParams.get("verif") == 1 &&
      searchParams.get("email") &&
      searchParams.get("code")
    ) {
      let data = new FormData();
      data.append("email", searchParams.get("email"));
      data.append("code", searchParams.get("code"));
      data.append("mode", 2);
      axios
        .post(process.env.REACT_APP_APIURL + "/auth.php", data)
        .then((res) => {
          if (res.data.status == "OK") {
            showModal(
              "Correcto",
              "Su usuario ha sido verificado, ya puede iniciar sesión"
            );
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  function handleSubmit(e) {
    setWrong(false);
    setLoadLogin(true);
    setChecking(true);
    e.preventDefault();
    let data = new FormData(e.target);
    data.append("mode", 0);
    axios
      .post(process.env.REACT_APP_APIURL + "/auth.php", data)
      .then((res) => {
        setLoadLogin(false);
        if (res.data.status == "OK") {
          setChecking(false);
          signIn(res.data.user.id, res.data.user.name, res.data.user.email);
          navigate("dashboard");
          console.log("Logged");
        } else if (res.data.status == "NOVERIF") {
          showModal(
            "Aviso",
            "Su usuario aún no está verificado, por favor revise su casilla de email."
          );
          setChecking(false);
        } else if (res.data.status == "BAD") {
          setWrong(true);
          setChecking(false);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        setLoadLogin(false);
        toast.error("Ha ocurrido un error conectando al servidor");
        console.log(error);
      });
  }
  return (
    <div
      className={
        "vh-100 vw-100 d-flex align-items-center justify-content-center p-md-3 " +
        styles.mainContainer
      }
    >
      <div
        className={
          "w-100 d-flex flex-column align-items-center row " +
          styles.loginContainer
        }
      >
        <div
          className={"d-none d-lg-flex col-6 h-100 " + styles.brandingLarge}
          style={{
            backgroundImage: `url(${loginback})`,
          }}
        ></div>
        <div className={"col-12 col-lg-6 pt-5 pt-md-4 " + styles.formContainer}>
          <img src={logogesp} className="w-50" style={{ maxWidth: "5cm" }} />
          <Form
            className={
              "d-flex align-items-center flex-column pt-md-0 w-100 mt-md-4 mb-md-4 pb-3  " +
              styles.form
            }
            onSubmit={handleSubmit}
          >
            <h4 className="mt-2 mb-3">Iniciar Sesión</h4>

            <Form.Group>
              <Form.Label className={styles.textBold}>Email</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="email">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Email"
                  name="email"
                  type="email"
                  aria-label="Email"
                  aria-describedby="email"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className={styles.textBold}>Contraseña</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="pass">
                  <FontAwesomeIcon icon={faKey} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Contraseña"
                  name="pass"
                  type="password"
                  aria-label="Contraseña"
                  aria-describedby="pass"
                  minLength={4}
                  required
                  maxLength={50}
                />
              </InputGroup>
            </Form.Group>
            <p
              className={styles.wrongPass}
              style={{ opacity: isWrong ? "1" : "0" }}
            >
              Email o contraseña incorrectos
            </p>
            <Button
              variant="primary"
              disabled={checking}
              type="submit"
              className="w-100 mt-2 mb-2"
            >
              {loadLogin ? (
                <FontAwesomeIcon icon={faUserClock} />
              ) : (
                "Iniciar Sesión"
              )}
            </Button>
            <Link to="/passreset">Olvidé mi contraseña</Link>
            <hr className={styles.hr} />
            <p className="mt-2">¿Aún no es usuario?</p>
            <Button
              variant="outline-primary"
              onClick={() => {
                navigate("register");
              }}
              type="button"
              className="w-100"
            >
              Registrarse
            </Button>
          </Form>

          <div
            className={
              styles.brandingMobile +
              " d-flex flex-column align-items-center mt-1 w-100 justify-content-start"
            }
          >
            <span
              className={
                "d-flex flex-row align-items-center justify-content-center mt-5 mb-2"
              }
            >
              <FontAwesomeIcon icon={faCode} />{" "}
              <h6 className="ms-2 mb-0">Developed by</h6>{" "}
            </span>
            <img
              src={logowaven}
              onClick={() =>
                (window.location.href = "http://www.wavensolutions.com")
              }
              alt="logo Waven Solutions"
              className={"w-25 mt-2 mb-5"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
