import React, { useContext, useState } from "react";
import styles from "./CrearEdificio.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import {
  faAdd,
  faClose,
  faDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faPencil,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../../load/LoadProvider";
import { ModalContext } from "../../../../modal/ModalContext";
import { AuthContext } from "../../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";

const CrearEdificio = () => {
  const [extras, setExtras] = useState([{ Nombre: "", Costo: 0 }]);
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const { admid } = useParams();

  const navigate = useNavigate();
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    data.append("mode", 2);
    data.append("adm", admid);

    axios
      .post(process.env.REACT_APP_APIURL + "/administraciones.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Edificio agregado correctamente");
          navigate(-1);
        } else {
          toast.error("Ha ocurrido un error agregando el edificio");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={"d-flex flex-column align-items-center pt-1"}>
      <hr className={"w-100"} />
      <h3>Crear Edificio</h3>
      <hr className={"w-25"} />
      <form
        onSubmit={handleSubmit}
        className={"d-flex flex-column align-items-center w-100"}
        style={{ maxWidth: "20cm" }}
      >
        <label className={"pb-2"}>
          <FontAwesomeIcon icon={faPencil} className={"me-2"} />
          <strong>Nombre:</strong>
        </label>
        <input
          type="text"
          className={"form-control mb-4"}
          name={"nombre"}
          maxLength={"50"}
          minLength={"3"}
          required
        />
        <label className={"pb-2"}>
          <FontAwesomeIcon icon={faUser} className={"me-2"} />
          <strong>Dirección:</strong>
        </label>
        <input
          type="text"
          className={"form-control mb-4"}
          name={"direccion"}
          maxLength={"100"}
          required
        />

        <button type="submit" className="btn btn-success w-100 mt-5 p-3 m-2">
          <FontAwesomeIcon icon={faAdd} className="me-2" />
          Crear
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="btn btn-outline-danger w-100 mt-2"
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default CrearEdificio;
