import React, { useContext, useEffect, useState } from "react";
import styles from "./Misreservas.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  faArrowLeft,
  faClock,
  faArrowRight,
  faEye,
  faClose,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoadContext } from "../../load/LoadProvider";

const Misreservas = () => {
  const [data, setData] = useState([]);
  const { setLoading, isLoading } = useContext(LoadContext);
  const { edifid, edifnombre } = useParams();

  let inicioMes = new Date();
  inicioMes.setDate(1);

  let finMes = new Date();
  finMes.setDate(1);
  finMes.setMonth(finMes.getMonth() + 1);
  finMes.setDate(finMes.getDate() - 1);

  const [fechaDesde, setFechaDesde] = useState(
    inicioMes.toISOString().substring(0, 10)
  );
  const [fechaHasta, setFechaHasta] = useState(
    finMes.toISOString().substring(0, 10)
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const [columnVisibility, setColumnVisibility] = useState({
    Reserva_ID: false,
    Moneda: false,
    Espacio: true,
    Dia: true,
    Turno: true,
    Total: true,
    Estado: true,
    Acciones: true,
    Pasada: false,
  });
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    updateData();
  }, [fechaDesde, fechaHasta]);
  useEffect(() => {
    updateData();
  }, []);
  const columnDef = [
    {
      accessorKey: "Reserva_ID",
      header: "Reserva_ID",
    },
    {
      accessorKey: "Moneda",
      header: "Moneda",
    },
    {
      accessorKey: "Espacio",
      header: "Espacio",
    },
    {
      accessorKey: "Dia",
      header: "Dia",
    },
    {
      accessorKey: "Turno",
      header: "Turno",
    },
    {
      accessorKey: "Total",
      header: "Total",
      cell: ({ row }) => (
        <>
          {row.original.Moneda}
          {row.original.Total}
        </>
      ),
    },
    {
      accessorKey: "Pasada",
      header: "Pasada",
    },

    {
      accessorKey: "Estado",
      header: "Estado",
      cell: ({ row }) => (
        <div>
          {row.original.Estado == 0 ? (
            <div style={{ color: "orange" }}>
              <strong>Pendiente</strong>
            </div>
          ) : row.original.Estado == 1 ? (
            <div style={{ color: "rgb(50, 180, 50)" }}>
              <strong>Confirmada</strong>
            </div>
          ) : row.original.Estado == 2 ? (
            <div style={{ color: "red" }}>
              <strong>Rechazada</strong>
            </div>
          ) : (
            <div style={{ color: "rgb(150, 0,0)" }}>
              <strong>Cancelada</strong>
            </div>
          )}
        </div>
      ),
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <div className="d-flex flex-row">
          {(row.original.Estado == 0 || row.original.Estado == 1) &&
          row.original.Pasada != 1 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <strong>Cancelar Reserva</strong>
                </Tooltip>
              }
            >
              <Button
                className={"btn btn-danger ms-3"}
                onClick={() => handleCancelarReserva(row.original.Reserva_ID)}
              >
                {" "}
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </OverlayTrigger>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const tableInstance = useReactTable({
    columns: columnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      //...
      columnVisibility,
      pagination,
    },
  });
  function handleCancelarReserva(reserva) {
    showModal(
      "Confirmación",
      "¿Está seguro/a que desea cancelar esta reserva?",
      () => {
        let data = new FormData();
        data.append("mode", 1);
        data.append("reserva", reserva);
        console.log(reserva);
        axios
          .post(process.env.REACT_APP_APIURL + "/reservas.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status == "OK") {
              toast.success("Reserva cancelada correctamente");
              updateData();
            } else {
              toast.error("Ha ocurrido un error eliminando");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate({ pathname: "/", search: searchParams.toString() });
                },
                () => {
                  navigate({ pathname: "/", search: searchParams.toString() });
                }
              );
            } else {
              toast.error("Ha ocurrido un error actualizando");
              console.log(error);
            }
          });
      }
    );
  }
  function updateData() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/reservas.php?mode=0&edif=" + edifid)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          if (res.data.status == "OK") {
            res.data.data = res.data.data.filter((reserva) => {
              return (
                fechaDesde == "" ||
                new Date(reserva.DiaC) >= new Date(fechaDesde)
              );
            });
            res.data.data = res.data.data.filter((reserva) => {
              return (
                fechaHasta == "" ||
                new Date(reserva.DiaC) <= new Date(fechaHasta)
              );
            });
            setData(res.data.data);
          } else {
            toast.error("Ha ocurrido un error actualizando");
            console.log(res);
          }
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate({ pathname: "/", search: searchParams.toString() });
            },
            () => {
              navigate({ pathname: "/", search: searchParams.toString() });
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <button
          onClick={() => {
            navigate({
              pathname: "/dashboard/misedificios",
              search: searchParams.toString(),
            });
          }}
          className={"btn btn-outline-primary text-center w-25 me-3"}
        >
          <FontAwesomeIcon className={"me-3"} icon={faArrowLeft} />
          Volver
        </button>
        <h3 className={"align-self-center ms-3 "}>
          <FontAwesomeIcon className={"me-2"} icon={faClock} />
          Mis Reservas de {edifnombre}
        </h3>
      </div>
      <div className={"w-100 " + styles.tableContainer}>
        <span className="pe-2 ps-2 d-flex flex-column mb-2">
          <div className="d-flex flex-row mt-3 align-items-center">
            <FontAwesomeIcon icon={faCalendar} className="me-4" />
            <strong className="me-2">Desde: </strong>
            <input
              type="date"
              className="form-control me-md-3"
              onChange={(e) => setFechaDesde(e.target.value)}
              max={fechaHasta}
              value={fechaDesde}
            />
          </div>
          <div className="d-flex flex-row mt-3 align-items-center">
            <FontAwesomeIcon icon={faCalendar} className="me-4" />
            <strong className="me-2">Hasta: </strong>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setFechaHasta(e.target.value)}
              value={fechaHasta}
              min={fechaDesde}
            />
          </div>
        </span>
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id} style={{ height: "1.3cm" }}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span
        className={"d-flex flex-row align-items-center justify-content-center"}
      >
        <button
          onClick={tableInstance.previousPage}
          disabled={!tableInstance.getCanPreviousPage()}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>{" "}
        <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
        <button
          disabled={!tableInstance.getCanNextPage()}
          onClick={tableInstance.nextPage}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </span>
    </div>
  );
};

export default Misreservas;
