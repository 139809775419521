import React, { useContext, useEffect, useRef } from "react";
import styles from "./Edificioinfo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../load/LoadProvider";
import { ModalContext } from "../../../modal/ModalContext";
import { AuthContext } from "../../../Auth/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";

const Edificioinfo = () => {
  const { edifid } = useParams();
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const nameInput = useRef();
  const direcInput = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    updateEdif();
  }, []);
  function updateEdif() {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_APIURL + "/edificios.php?mode=5&edif=" + edifid
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          nameInput.current.value = res.data.data.Nombre;
          direcInput.current.value = res.data.data.Direccion;
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.request && error.request.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error en la conexión al servidor");
          console.log(error);
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    showModal(
      "Confirmación",
      "¿Está seguro/a que desea modificar los datos del edificio?",
      () => {
        setLoading(true);
        const data = new FormData(e.target);
        data.append("mode", 2);
        data.append("edif", edifid);

        axios
          .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
          .then((res) => {
            setLoading(false);
            if (res.data.status === "OK") {
              toast.success("Edificio modificado correctamente");
              updateEdif();
            } else if (res.data.status === "NOMODIF") {
              toast.error("No ha modificado el edificio");
            } else {
              toast.error("Ha ocurrido un error en la conexión al servidor");
              console.log(res);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.request && error.request.status == 401) {
              signOut();
              showModal(
                "Error de autenticación",
                "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                () => {
                  navigate("/");
                },
                () => {
                  navigate("/");
                }
              );
            } else {
              toast.error("Ha ocurrido un error en la conexión al servidor");
              console.log(error);
            }
          });
      }
    );
  }
  return (
    <div className=" w-100 d-flex flex-column align-items-center pt-4">
      <form
        className={"d-flex flex-column w-100 align-items-center " + styles.form}
        onSubmit={handleSubmit}
      >
        <label className={"pb-2"}>
          <strong>Nombre</strong>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Nombre"
          name="nombre"
          maxLength={50}
          ref={nameInput}
        />
        <label className={"pb-2 mt-4"}>
          <strong>Dirección</strong>
        </label>
        <input
          type="text"
          name="direccion"
          className="form-control"
          maxLength={100}
          placeholder="Dirección"
          ref={direcInput}
        />
        <button type="submit" className="btn btn-success mt-5 w-100">
          Confirmar
        </button>
      </form>
    </div>
  );
};

export default Edificioinfo;
