import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import {
  faAdd,
  faCalendar,
  faClose,
  faDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faPencil,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { LoadContext } from "../../../../../load/LoadProvider";
import { ModalContext } from "../../../../modal/ModalContext";
import { AuthContext } from "../../../../Auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";

const ModificarEspacio = () => {
  const [extras, setExtras] = useState([{ Nombre: "", Costo: 0 }]);
  const { setLoading } = useContext(LoadContext);
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const { edifid, espid } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    getEspacio();
  }, []);

  const navigate = useNavigate();
  function getEspacio() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/edificios.php?mode=9&esp=" + espid)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "OK") {
          console.log(res);
          setData(res.data.data);
          setExtras(res.data.data.extras);
        } else {
          toast.error("Ha ocurrido un error agregando el espacio");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.target);
    data.append("extras", JSON.stringify(extras));
    data.append("mode", 8);
    data.append("esp", espid);

    axios
      .post(process.env.REACT_APP_APIURL + "/edificios.php", data)
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          toast.success("Espacio modificado correctamente");
          navigate(-1);
        } else {
          toast.error("Ha ocurrido un error agregando el espacio");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={"d-flex flex-column align-items-center pt-1"}>
      <hr className={"w-100"} />
      <h3>Modificar Espacio</h3>
      <hr className={"w-25"} />
      {data ? (
        <form
          onSubmit={handleSubmit}
          className={"d-flex flex-column align-items-center w-100"}
          style={{ maxWidth: "20cm" }}
        >
          <div className={"d-flex flex-column align-items-center flex-md-row"}>
            <div
              className={
                "col-12 col-md-6 d-flex flex-column align-items-center p-3"
              }
            >
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faPencil} className={"me-2"} />
                <strong>Nombre:</strong>
              </label>
              <input
                type="text"
                className={"form-control mb-4"}
                name={"nombre"}
                maxLength={"50"}
                minLength={"3"}
                defaultValue={data.Nombre}
                required
              />
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faUser} className={"me-2"} />
                <strong>Cupo:</strong>
              </label>
              <input
                type="number"
                className={"form-control mb-4"}
                name={"cupo"}
                maxLength={"5"}
                defaultValue={data.Cupo}
                required
              />
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faLockOpen} className={"me-2"} />
                <strong>Permisos:</strong>
              </label>
              <Form.Select
                className={"mb-4"}
                name={"permisos"}
                defaultValue={data.Permisos}
                required
              >
                <option value="AP">Propietarios</option>
                <option value="AI">Inquilinos</option>
                <option value="API">Propietarios e Inquilinos</option>
              </Form.Select>
            </div>
            <div
              className={
                "col-12 col-md-6 d-flex flex-column align-items-center p-3"
              }
            >
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faPhone} className={"me-2"} />
                <strong>Teléfono Mantenimiento:</strong>
              </label>
              <input
                type="text"
                className={"form-control mb-4"}
                name={"telefono"}
                maxLength={"10"}
                defaultValue={data.Telefono}
                minLength={"3"}
                required
              />
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faEnvelope} className={"me-2"} />
                <strong>Email Mantenimiento:</strong>
              </label>
              <input
                type="text"
                className={"form-control mb-4"}
                name={"email"}
                maxLength={"50"}
                defaultValue={data.Email}
                minLength={"3"}
                required
              />
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faDollar} className={"me-2"} />
                <strong>Moneda:</strong>
              </label>
              <Form.Select
                className={"mb-4"}
                defaultValue={data.Moneda}
                name={"moneda"}
                required
              >
                <option value="UYU">Pesos</option>
                <option value="USD">Dolares</option>
                <option value="UI">Unidades Indexadas</option>
                <option value="UR">Unidades Reajustables</option>
              </Form.Select>
              <label className={"pb-2"}>
                <FontAwesomeIcon icon={faCalendar} className={"me-2"} />
                <strong>Límite de cancelación (Días):</strong>
              </label>
              <input
                type="number"
                className={"form-control mb-4"}
                name={"cancelacion"}
                defaultValue={data.Cancelacion}
                max={365}
                min={0}
                required
              />
            </div>
          </div>
          <div className={"col-12 d-flex flex-column align-items-center pb-5"}>
            <label>
              <h4>Extras:</h4>
            </label>
            <div
              className={"w-100 d-grid"}
              style={{
                maxWidth: "10cm",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1rem",
              }}
            >
              <div>
                <strong>Extra</strong>
              </div>
              <div>
                <strong>Costo</strong>
              </div>
              <div>
                <strong>Eliminar</strong>
              </div>
              {extras.map((extra, index) => {
                return (
                  <>
                    <div key={index + "extNombre"}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        value={extra.Nombre}
                        onChange={(e) => {
                          let nuevo = [...extras];
                          nuevo[index].Nombre = e.target.value;
                          setExtras(nuevo);
                        }}
                      />
                    </div>
                    <div key={index + "extCosto"}>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Costo"
                        value={extra.Costo}
                        onChange={(e) => {
                          let nuevo = [...extras];
                          nuevo[index].Costo = e.target.value;
                          setExtras(nuevo);
                        }}
                      />
                    </div>
                    <div key={index + "extElim"}>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => {
                          let nuevo = [...extras];
                          nuevo.splice(index, 1);
                          setExtras(nuevo);
                        }}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </button>
                    </div>
                  </>
                );
              })}
              {
                <div style={{ gridColumn: "span 2" }}>
                  <button
                    type="button"
                    className={"w-100 btn btn-success mt-2"}
                    onClick={() => {
                      let nuevo = [...extras];
                      nuevo.push({ Nombre: "", Costo: 0 });
                      setExtras(nuevo);
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faAdd} />
                  </button>
                </div>
              }
            </div>
            <button
              type="submit"
              className="btn btn-warning w-100 mt-5 p-3 m-2"
            >
              <FontAwesomeIcon icon={faPencil} className="me-2" />
              Modificar
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn btn-outline-danger w-100 mt-2"
            >
              Cancelar
            </button>
          </div>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export default ModificarEspacio;
