import React from "react";
import styles from "./Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Modal = ({
  title,
  text,
  show,
  confirmFunction,
  cancelFunction,
  closeModal,
}) => {
  return (
    <>
      {show ? (
        <div className={styles.mainContainer}>
          <div className={"rounded-5 " + styles.container}>
            <div
              className={"w-100 rounded-top-5 d-flex flex-row " + styles.topBar}
            >
              <h2>{title}</h2>
            </div>
            <p className={styles.mainText + " m-5"}>{text}</p>
            <div className={styles.buttonsContainer}>
              <button
                type="button"
                onClick={() => {
                  confirmFunction();
                  closeModal();
                }}
                className="btn btn-success"
              >
                {" "}
                Aceptar
              </button>
              <button
                type="button"
                onClick={() => {
                  cancelFunction();
                  closeModal();
                }}
                className="btn btn-outline-danger"
              >
                {" "}
                Cancelar
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Modal;
