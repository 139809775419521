import React, { createContext, useState } from "react";
import styles from "./LoadProvider.module.css";

export const LoadContext = createContext();

const LoadProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <LoadContext.Provider value={{ isLoading, setLoading }}>
      <div
        className={styles.loadContainer}
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <div className={styles.dotsCss}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      </div>
      {children}
    </LoadContext.Provider>
  );
};

export default LoadProvider;
