import React, { useContext, useEffect, useState } from "react";
import styles from "./Edificios.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  filterFns,
} from "@tanstack/react-table";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalContext } from "../modal/ModalContext";
import { AuthContext } from "../Auth/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  faArrowLeft,
  faClock,
  faArrowRight,
  faGear,
  faArrowDown,
  faChevronRight,
  faChevronDown,
  faC,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoadContext } from "../../load/LoadProvider";

const Edificios = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { setLoading, isLoading } = useContext(LoadContext);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const { showModal } = useContext(ModalContext);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({
    Edif_ID: false,
    Administracion: true,
    Nombre: true,
    Unidad: true,
    Direccion: true,
    Acciones: true,
  });
  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    //FILTRO
    if (data) {
      let newData = structuredClone(data);
      newData.forEach((adm) => {
        adm.subRows = adm.subRows.filter((edif) => {
          return (
            edif["Nombre"].toLowerCase().includes(globalFilter.toLowerCase()) ||
            edif["Direccion"].toLowerCase().includes(globalFilter.toLowerCase())
          );
        });
      });
      newData = newData.filter((adm) => adm.subRows.length > 0);
      setFilteredData(newData);
    }
  }, [globalFilter]);
  const columnDef = [
    {
      accessorKey: "Administracion",
      header: "Administración",
      cell: ({ row, getValue }) => (
        <div key={row.id}>
          <strong>{getValue()}</strong>
          {row.getCanExpand() && (
            <button
              key={row.id}
              className={"btn btn-outline-light ms-2"}
              style={{ border: "none" }}
              onClick={row.getToggleExpandedHandler()}
            >
              <FontAwesomeIcon
                icon={row.getIsExpanded() ? faChevronDown : faChevronRight}
              />
            </button>
          )}
        </div>
      ),
    },
    {
      accessorKey: "Edif_ID",
      header: "Edif_ID",
    },
    {
      accessorKey: "Nombre",
      header: "Nombre",
    },
    {
      accessorKey: "Direccion",
      header: "Dirección",
    },
    {
      header: "Acciones",
      cell: ({ row }) => (
        <>
          {!row.getCanExpand() && (
            <div className="d-flex flex-row">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <strong>Administrar</strong>
                  </Tooltip>
                }
              >
                <Button
                  className={"btn btn-warning me-2"}
                  onClick={() =>
                    navigate("administrar/" + row.original.Edif_ID + "/info")
                  }
                >
                  {" "}
                  <FontAwesomeIcon icon={faGear} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    <strong>Reservar</strong>
                  </Tooltip>
                }
              >
                <Button
                  className={"btn btn-success ms-3"}
                  onClick={() =>
                    navigate(
                      "/dashboard/misedificios/reservar/" + row.original.Edif_ID
                    )
                  }
                >
                  {" "}
                  <FontAwesomeIcon icon={faClock} />
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </>
      ),
    },
  ];

  //GPT

  const tableInstance = useReactTable({
    enableGlobalFilter: true,
    columns: columnDef,
    data: filteredData,
    filterFromLeafRows: true,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getExpandedRowModel: getExpandedRowModel(),
    //getFilteredRowModel: getFilteredRowModel(),
    onExpandedChange: setExpanded,
    //onGlobalFilterChange: setGlobalFilter,
    state: {
      //...
      pagination,
      expanded,
      columnVisibility,
      //globalFilter,
    },
  });

  function updateData() {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_APIURL + "/edificios.php?mode=1")
      .then((res) => {
        setLoading(false);
        if (res.data.status == "OK") {
          setData(res.data.data);
          setFilteredData(res.data.data);
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(res);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          signOut();
          showModal(
            "Error de autenticación",
            "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            () => {
              navigate("/");
            },
            () => {
              navigate("/");
            }
          );
        } else {
          toast.error("Ha ocurrido un error actualizando");
          console.log(error);
        }
      });
  }
  return (
    <div className={styles.mainContainer}>
      <h3 className={"mt-4"}>Gestionar Edificios</h3>
      <hr className={styles.hr} />
      <span
        className="w-100 d-flex flex-row align-items-center justify-content-center pb-3"
        style={{ maxWidth: "10cm" }}
      >
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          placeholder="Nombre/Dirección"
          className="form-control ms-3"
          value={globalFilter}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
            //setSearchParams({ search: e.target.value });
          }}
        />
      </span>
      <div className={"w-100 " + styles.tableContainer}>
        <table className={"w-100 table table-striped"}>
          <thead className={"thead-light"}>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((col) => (
                  <th key={col.id}>{col.column.columnDef.header}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr
                className={
                  !row.getCanExpand() ? styles.rowExpand : styles.rowGroup
                }
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span
        className={"d-flex flex-row align-items-center justify-content-center"}
      >
        <button
          onClick={tableInstance.previousPagePage}
          disabled={!tableInstance.getCanPreviousPage()}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>{" "}
        <strong className={"m-3"}>Página: {pagination.pageIndex + 1}</strong>
        <button
          disabled={!tableInstance.getCanNextPage()}
          onClick={tableInstance.nextPage}
          className={"btn btn-outline-primary"}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </span>
    </div>
  );
};

export default Edificios;
